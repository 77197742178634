import { FC, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../api/config";
import { useUserContext } from "../../context/UserContext";

const Protected = (props: any) => {
  const { user, setUser } = useUserContext();
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      navigate("/admin/login");
    }
  }, [error]);

  if (!user) {
    if (typeof window !== "undefined") {
      const token = window.localStorage.getItem("token-jahuel");
      if (token) {
        fetch(BASE_URL + "users/validate", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            token,
          }),
        })
          .then((data) => {
            if (data.status === 200) {
              console.log("status 200");
              return setUser({ name: "admin", email: "admin", token });
            }
            navigate("/admin/login");
          })
          .catch((err) => {
            console.log("error 3");
            setError(true);
          });
      } else {
        return <Navigate to="/admin/login" replace />;
      }
    }
  }

  return <>{props.children}</>;
};

export default Protected;
