import styled from "@emotion/styled";
import { TextField } from "@mui/material";

const color = '#FFF !important';

export const WhiteTextField = styled(TextField)({
  width: "100%",
  maxWidth: "256px",
  marginBottom: "24px",
  '& label': {
    color: color,
  },
  '& .MuiInput-underline::before': {
    'border-color': color
  },
  '& .MuiInput-underline::after': {
    'border-color': color
  }
});