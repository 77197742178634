import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { Link } from 'react-router-dom';
import { AttachMoney, Discount, DoorFront, Inventory, ViewWeek } from '@mui/icons-material';

export const mainListItems = (
  <React.Fragment>
    <Link to="/admin/dashboard">
      <ListItemButton>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Reservas" />
      </ListItemButton>
    </Link>
    <Link to="/admin/habitaciones">
      <ListItemButton>
        <ListItemIcon>
          <DoorFront />
        </ListItemIcon>
        <ListItemText primary="Habitaciones" />
      </ListItemButton>
    </Link>
    <Link to="/admin/programas">
      <ListItemButton>
        <ListItemIcon>
          <Inventory />
        </ListItemIcon>
        <ListItemText primary="Programas" />
      </ListItemButton>
    </Link>
    <Link to="/admin/tarifas">
      <ListItemButton>
        <ListItemIcon>
          <AttachMoney />
        </ListItemIcon>
        <ListItemText primary="Tarifas" />
      </ListItemButton>
    </Link>
    <Link to="/admin/cupones">
      <ListItemButton>
        <ListItemIcon>
          <Discount />
        </ListItemIcon>
        <ListItemText primary="Cupones" />
      </ListItemButton>
    </Link>
    <Link to="/admin/dias-por-programa">
      <ListItemButton>
        <ListItemIcon>
          <ViewWeek />
        </ListItemIcon>
        <ListItemText primary="Administrar Checkin/Checkout" sx={{whiteSpace: 'normal'}} />
      </ListItemButton>
    </Link>
    <Link to="/admin/feriados">
      <ListItemButton>
        <ListItemIcon>
          <ViewWeek />
        </ListItemIcon>
        <ListItemText primary="Feriados" />
      </ListItemButton>
    </Link>
    <Link to="/admin/programas/disponibilidades">
      <ListItemButton>
        <ListItemIcon>
          <ViewWeek />
        </ListItemIcon>
        <ListItemText primary="Disponibilidad de programas"  sx={{whiteSpace: 'normal'}}/>
      </ListItemButton>
    </Link>
  </React.Fragment >
);