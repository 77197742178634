import { BASE_URL } from "./config";

export const getAllProgramAvailabilities = async (token: string) => {
    const apiCall = await fetch(BASE_URL + 'program-availabilities/', {
        headers: { 'x-auth-token': token }
    });

    const response = await apiCall.json();

    return response;
}

export const getProgramAvailabilitiesByProgramId = async (token: string, id: number) => {
    const apiCall = await fetch(`${BASE_URL}program-availabilities/${id}`, {
        headers: { 'x-auth-token': token }
    });

    const response = await apiCall.json();

    return response;
}

export const createProgramAvailability = async (token: string, body: unknown) => {
    const apiCall = await fetch(BASE_URL + 'program-availabilities', {
        headers: { 'x-auth-token': token, 'Content-Type': 'application/json' },
        method: "POST",
        body: JSON.stringify(body)
    })

    const response = await apiCall.json();

    return response?.data;
}

export const updateProgramAvailability = async (token: string, body: unknown, id: string) => {
    const apiCall = await fetch(`${BASE_URL}program-availabilities/${id}`, {
        headers: { 'x-auth-token': token, 'Content-Type': 'application/json' },
        method: "PUT",
        body: JSON.stringify(body)
    })

    const response = await apiCall.json();

    return response?.data;
}

export const deleteProgramAvailability = async (token: string, id: number) => {
    const apiCall = await fetch(`${BASE_URL}program-availabilities/${id}`, {
        headers: { 'x-auth-token': token, 'Content-Type': 'application/json' },
        method: "DELETE",
    })

    const response = await apiCall.json();

    return response?.data;
}