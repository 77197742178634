import styled from "@emotion/styled";
import { alpha, Card, Container, Grid } from "@mui/material";
import { StaticDatePicker } from "@mui/x-date-pickers";

export const StyledContainer = styled(Container)({
  backgroundImage: `url('images/background-1.jpg')`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  minHeight: "100vh",
  height: "fit-content",
});

export const StyledGrid = styled(Grid)({
  justifyContent: "center",
  alignItems: "center",
  alignContent: "center",
  height: "100%",
})

export const StyledDateFormCard = styled(Card)({
  backgroundColor:  alpha("#000", 0.6),
  borderRadius: "15px",
});

export const StyledStaticDatePicker = styled(StaticDatePicker)({
  maxWidth: "320px",
  "& .MuiPickerStaticWrapper-content": {
    marginBottom: "8px",
    minWidth: "200px"
  }
})
