import { Box, Button, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Modal, Paper, Select, SelectChangeEvent, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { BASE_URL } from "../../../../api/config";
import { Program, ProgramDay } from "../../../../../types/context";
import Title from "../../../../common/components/Title";
import { useUserContext } from "../../../../context/UserContext";
import ProgramDayRow from "./ProgramDayRow";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  maxHeight: '90%',
  overflow: 'auto'
};

const days = [
  {
    day_number: 1,
    name: 'Lunes'
  },
  {
    day_number: 2,
    name: 'Martes'
  },
  {
    day_number: 3,
    name: 'Miércoles'
  },
  {
    day_number: 4,
    name: 'Jueves'
  },
  {
    day_number: 5,
    name: 'Viernes'
  },
  {
    day_number: 6,
    name: 'Sábado'
  },
  {
    day_number: 0,
    name: 'Domingo'
  },
]

const ProgramDays: FC = () => {
  const { user } = useUserContext();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [programs, setPrograms] = useState<Array<Program>>([]);
  const [programDays, setProgramDays] = useState<Array<ProgramDay>>([]);
  const [newProgramDayData, setNewProgramDayData] = useState<{
    program_id?: number,
    day_id?: number,
    is_checkin_allowed?: boolean,
    is_checkout_allowed?: boolean
  }>({is_checkin_allowed: false, is_checkout_allowed: false});
  const handleNewProgramDay = (key: keyof typeof newProgramDayData, value: number | boolean) => setNewProgramDayData(prev => ({ ...prev, [key]: value }))

  const handleSelectedProgram = (e: SelectChangeEvent<string>) => {
    setProgramDays([]);
    handleNewProgramDay('program_id', Number(e.target.value))
  };

  const loadProgramDays = (token: string) => {
    fetch(BASE_URL + 'program-days/program/' + newProgramDayData.program_id, {
      headers: { 'x-auth-token': token }
    })
      .then(response => response.json())
      .then(data => setProgramDays(data.programDays))
      .catch(err => console.log(err))
  }

  const createNewProgramDay = () => {
    if (user) {
      fetch(BASE_URL + 'program-days', {
        body: JSON.stringify({ program_day: newProgramDayData }),
        method: 'POST',
        headers: {
          'x-auth-token': user.token,
          'Content-Type': 'application/json'
        }
      }).then(response => {
        if(response.status === 200){
          loadProgramDays(user.token)
        }
        return response.json()
      })
        .then(data => {
          handleClose()
        })
        .catch(e => console.log(e))
    }
  }

  useEffect(() => {
    if (user) {
      fetch(BASE_URL + 'programs/all', {
        headers: { 'x-auth-token': user.token }
      })
        .then(data => data.json())
        .then(response => setPrograms(response as Array<Program>));
    }
  }, [user])

  useEffect(() => {
    if (newProgramDayData.program_id && user) {
      loadProgramDays(user.token);
    }
  }, [newProgramDayData.program_id, user])

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h4" component="h2" marginBottom={4}>
            Nuevo día
          </Typography>
          <Box
            component="form"
            display='flex'
            flexDirection='column'
            noValidate
            autoComplete="off"
          >

            <InputLabel id="days-label">Día</InputLabel>
            <Select<number>
              sx={{
                marginBottom: "24px",
                width: "50%"
              }}
              labelId="days-label"
              id="days-dropdown"
              value={newProgramDayData.day_id}
              label="Días"
              onChange={(e: SelectChangeEvent<number>) => handleNewProgramDay('day_id', Number(e.target.value))}
            >
              {days && days.length > 0 && days.map(day => (<MenuItem value={day.day_number}>{day.name} </MenuItem>))}
            </Select>
            <FormGroup sx={{ marginBottom: 4 }}>
              <FormControlLabel
                control={
                  <Switch
                    value={newProgramDayData.is_checkin_allowed}
                    checked={newProgramDayData.is_checkin_allowed}
                    onClick={() => handleNewProgramDay('is_checkin_allowed', !newProgramDayData.is_checkin_allowed)}
                  />}
                label="Permite Checkin"
              />
              <FormControlLabel
                control={
                  <Switch
                    value={newProgramDayData.is_checkout_allowed}
                    checked={newProgramDayData.is_checkout_allowed}
                    onClick={() => handleNewProgramDay('is_checkout_allowed', !newProgramDayData.is_checkout_allowed)} />} label="Permite Checkout"
              />
            </FormGroup>

            <Grid
              marginTop={2}
              display='flex'
              justifyContent='center'
            >
              <Button
                variant='contained'
                onClick={createNewProgramDay}
                style={{
                  width: '20%'
                }}
              >
                Crear
              </Button>
            </Grid>
          </Box>
        </Box>
      </Modal>
      <InputLabel id="programs-label">Seleccione un programa</InputLabel>
      <Select
        sx={{
          marginBottom: "24px",
          width: "90%"
        }}
        labelId="programs-label"
        id="programs-dropdown"
        label="Programas"
        onChange={handleSelectedProgram}
      >
        {programs && programs.length > 0 && programs.map(program => (<MenuItem value={program.id}>{program.name}</MenuItem>))}
      </Select>
      {newProgramDayData.program_id && (<>
        <Grid
          display='flex'
          minWidth='100%'
          justifyContent='space-between'
          marginBottom={2}
          marginTop={2}
        >
          <Title>Días por Programa</Title>
          <Button
            variant='contained'
            onClick={handleOpen}
          >
            Nuevo Día
          </Button>
        </Grid>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Día</TableCell>
                <TableCell>Permite Checkin</TableCell>
                <TableCell>Permite Checkout</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {newProgramDayData.program_id && programDays && programDays.length > 0 && (
                programDays.map(programDay => (
                  <ProgramDayRow
                    day_id={programDay.day_id}
                    is_allowed_checkin={programDay.is_checkin_allowed}
                    is_allowed_checkout={programDay.is_checkout_allowed}
                    name={days.find(d => d.day_number === programDay.day_id)?.name || ""}
                    program_id={programDay.program_id}
                  />
                )))}
            </TableBody>
          </Table>
        </TableContainer>
      </>)}
    </>
  )
}

export default ProgramDays;
