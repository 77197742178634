import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../../../../common/components/Title';
import { BASE_URL } from '../../../../api/config';
import { Reservation } from '../../../../../types/reservation';
import { useUserContext } from '../../../../context/UserContext';
import dayjs from 'dayjs';


function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}

export default function Reservations() {
  const { user } = useUserContext();
  const [status, setStatus] = useState('SUCCESS');
  const [reservations, setReservations] = useState<Array<Reservation>>([]);

  useEffect(() => {
    if (user) {
      const query = `?status=${status}`
      fetch(BASE_URL + 'reservations' + query, {
        headers: {
          "x-auth-token": user.token
        }
      })
        .then(data => {
          if (data.status === 200) {
            return data.json()
          }
          throw new Error('Algo malo ocurrió');
        })
        .then(response => setReservations(response as Array<Reservation>));
    }
  }, [status, user])

  return (
    <>
      <Title>Reservas</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Order Id</TableCell>
            <TableCell>Nombre</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Teléfono</TableCell>
            <TableCell>Programa</TableCell>
            <TableCell>Habitación</TableCell>
            <TableCell>Checkin</TableCell>
            <TableCell>Checkout</TableCell>
            <TableCell>Fecha de transacción</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reservations.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.session_id}</TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.email}</TableCell>
              <TableCell>{row.phone}</TableCell>
              <TableCell>{row.program?.name}</TableCell>
              <TableCell>{row.room?.name}</TableCell>
              <TableCell>{row.start_date}</TableCell>
              <TableCell>{row.end_date}</TableCell>
              <TableCell>{row.purchase_date ? dayjs(row.purchase_date).format('YYYY-MM-DD HH:mm') : ''}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}