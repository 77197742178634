import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, InputLabel, ListItemText, MenuItem, Modal, Select, SelectChangeEvent, Switch, TextField, Typography } from "@mui/material"
import { ChangeEvent, FC } from "react";
import { style } from "../../../../common/styles/modal";
import Title from "../../../../common/components/Title";
import ProgramCard from "../../../step1/components/ProgramCard";
import { days } from "../../../../common/constants";
import { useUserContext } from "../../../../context/UserContext";
import { ProgramInput } from "../../../../../types/context";
import { updateProgram, createProgram } from "../../../../api/programs";
import { formatNumber } from "../../../../utils/helpers";

type ProgramModalProps = {
  id?: string,
  name?: string,
  image?: string,
  is_active?: boolean,
  description?: string,
  hasAvailability?: boolean,
  is_special_day?: boolean,
  program_days?: Array<string>,
  details?: string,
  duration?: number,
  suggested?: boolean,
  not_allowed?: string,
  handleChange: (key: keyof ProgramInput, value: string | boolean | string[] | number) => void,
  isOpen: boolean,
  handleClose: () => void,
  isEditing?: boolean,
  handleReloadPrograms?: (token: string) => void,
}


const ProgramModal: FC<ProgramModalProps> = (props: ProgramModalProps) => {
  const {
    id,
    name,
    isOpen,
    description,
    hasAvailability,
    image,
    is_active,
    is_special_day,
    program_days,
    details,
    duration,
    suggested,
    not_allowed,
    handleClose,
    handleChange,
    isEditing,
    handleReloadPrograms,
  } = props;

  const { user } = useUserContext();
  const handleDurationChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = formatNumber(e.target.value);
    if((value || value === 0) && value >= 0){
      return handleChange('duration', value)
    }

    handleChange('duration', duration || 0);
  }
  const mapDescriptionToLi = (description: string | undefined) => description ? description.split('\n').map(item => `<li>${item}</li>`).join('') : '';
  const handleCreateOrUpdateProgram = () => {
    if (user) {
      const program = {
        name,
        description: mapDescriptionToLi(description),
        hasAvailability,
        is_active,
        is_special_day,
        program_days,
        details,
        duration,
        suggested,
        not_allowed,
        image: image ?? 'programa-pausa.jpg',
      }

      const action = id ? updateProgram(user.token, program, id) : createProgram(user.token, program);

      action.then(data => {
        handleClose();
        handleReloadPrograms?.(user.token);
      })
        .catch(e => console.log(e))
    }
  }

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h4" component="h2" marginBottom={4}>
          {isEditing ? 'Editar Programa' : 'Nuevo Programa'}
        </Typography>
        <Box
          component="form"
          display='flex'
          flexDirection='row'
          noValidate
          autoComplete="off"
        >
          <div style={{ width: "50%" }}>
            <div style={{ marginBottom: "20px" }}>
              <TextField
                sx={{
                  minWidth: "90%",
                  marginBottom: 1
                }}
                required
                id="name"
                label="Nombre"
                placeholder='Nombre del Programa'
                variant="standard"
                value={name}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange('name', e.target.value)}
              />
              <TextField
                sx={{
                  minWidth: "90%",
                  marginBottom: 1
                }}
                required
                id="image"
                label="Imagen"
                placeholder='Nombre de imagen'
                variant="standard"
                helperText="Si quiere usar una imagen por defecto, dejar vacío"
                value={image}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange('image', e.target.value)}
              />
              {// Descomentar cuando se implementé un servicio que guarde imágenes
                            /* <input
                                accept="image/*"
                                style={{ display: 'none' }}
                                id="raised-button-file"
                                multiple
                                type="file"
                            />
                            <label htmlFor="raised-button-file">
                                <Button variant='contained' component="span" sx={{ marginTop: 2 }}>
                                Subir imagen
                                </Button>
                            </label> */}
            </div>
            <TextField
              sx={{
                minWidth: "90%"
              }}
              fullWidth
              id="description"
              label="Descripción"
              placeholder="Descripción del programa"
              multiline
              helperText='Los saltos de línea marcan los elementos de la lista desplegable. Esto corresponde a la sección "Ver lo que incluye"'
              value={description}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange('description', e.target.value)}
            />
            <FormGroup sx={{ marginBottom: 4 }}>
              <FormControlLabel
                control={<Switch value={is_active} checked={is_active} onClick={() => handleChange('is_active', !is_active)} />}
                label="Activo"
              />
              <FormControlLabel control={<Switch value={hasAvailability} checked={hasAvailability} onClick={() => handleChange('hasAvailability', !hasAvailability)} />} label="Siempre disponible" />
              <FormControlLabel control={<Switch value={is_special_day} checked={is_special_day} onClick={() => handleChange('is_special_day', !is_special_day)} />} label="Fin de semana largo" />
            </FormGroup>

            <TextField
                sx={{
                  minWidth: "20%",
                  marginBottom: 4
                }}
                id="duration"
                label="Duración"
                placeholder="Duración del programa"
                helperText="Valida que la diferencia entre inicio y fin sea exactamente la duración. Dejar en cero para saltar."
                value={duration}
                onChange={handleDurationChange}
              />

            <InputLabel id="days-label">Días en que aplica</InputLabel>
            <Select
              sx={{
                marginBottom: "24px",
                width: "90%"
              }}
              labelId="days-label"
              id="days-dropdown"
              value={program_days || []}
              label="Días"
              multiple
              renderValue={(selected) => selected.map(val => days.find(item => item.id === val)?.name).join(', ')}
              onChange={(e: SelectChangeEvent<string[]>) => handleChange('program_days', e.target.value)}
            >
              {days && days.length > 0 && days.map(day => (<MenuItem value={day.id}>
                <Checkbox checked={program_days && program_days.indexOf(day.id) > -1} />
                <ListItemText primary={day.name} />
              </MenuItem>))}
            </Select>
          </div>
          <hr />
          <Grid style={{ width: "45%" }}>
            <Title>Vista Previa</Title>
            <ProgramCard
              title={name || ''}
              program={{
                id: '1',
                name: name || '',
                image: image || 'programa-pausa.jpg',
                is_active: is_active || false,
                description: mapDescriptionToLi(description),
                details: details || '',
                duration: duration || 0,
                hasAvailability: hasAvailability || false,
                rooms: [],
                suggested: suggested || false,
                not_allowed: not_allowed,
              }}
            />
            <Grid
              marginTop={2}
              display='flex'
              justifyContent='center'
            >
              <Button
                variant='contained'
                onClick={handleCreateOrUpdateProgram}
                style={{
                  width: '50%'
                }}
              >
                {isEditing ? 'Editar' : 'Crear'}
              </Button>
            </Grid>
          </Grid>

        </Box>
      </Box>
    </Modal>
  )
}

export default ProgramModal;