import dayjs, { Dayjs } from "dayjs"

export const isProduction = (): boolean => {
  return process.env.NODE_ENV === 'production'
}

export const formatDate = (date?: string | Dayjs) => { 
  if (date) return typeof date === 'string' ? dayjs(date).format("YYYY-MM-DD") : date.format("YYYY-MM-DD") 
}

export const formatNumber = (value: string) => {
  const num = Number(value);
  console.log(num);
  if(!Number.isNaN(num)){
    return num;
  }

  return undefined;
}