import React, { FC } from 'react';
import { Step1Props } from '../../../types/step1';
import Header from '../../common/components/Header';
import { StyledContainer, StyledGrid } from './date-selection';
import DateForm from './components/DateForm';
import { Alert, Grid, Snackbar, Typography } from '@mui/material';
import { useSnackbarContext } from '../../context/SnackbarContext';


const DateSelection: FC = () => {
  const { isOpen, message, showSnackBar } = useSnackbarContext();
  return (
    <StyledContainer maxWidth={false} disableGutters>
      <Header opacity={0.6} />
      <StyledGrid
        container
        xs={12}
        padding={4}
        alignItems="center"
        alignContent="center"
        justifyContent="center"
        justifyItems="center"
      >
        <Grid item height="100%" justifyContent="center" xs={12}
          sm={6}
          md={12}>
          <DateForm />
        </Grid>
      </StyledGrid>
      <Snackbar
        open={isOpen}
        autoHideDuration={5000}
        onClose={() => showSnackBar(false, '')}
        sx={{
          height: "100px"
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Alert severity="error">
          <Typography fontSize={20}>{message}</Typography>
        </Alert>
      </Snackbar>
    </StyledContainer>
  )
}

export default DateSelection;