import { Button, ThemeProvider, Typography } from "@mui/material"
import { FC } from "react"
import { YellowButtonProps } from "../../../types/shared"
import calendarTheme from "../../themes/calendar"

const YellowButton: FC<YellowButtonProps> = (props: YellowButtonProps) => {
  const { text, handleSubmit, disabled, width } = props;
  return (
    <ThemeProvider theme={calendarTheme}>
      <Button
        color="primary"
        variant="contained"
        onClick={handleSubmit}
        disabled={disabled}
        sx = {{
          maxHeight: "50px",
          width: width
        }}
      >
        <Typography color="white">{text}</Typography>
      </Button>
    </ThemeProvider>
  )
}

export default YellowButton;