export enum BookingContextActionEnum {
  ADD_ADULT = "ADD_ADULT",
  ADD_CHILD = "ADD_CHILD",
  ADD_INFANT = "ADD_INFANT",
  REMOVE_ADULT = "REMOVE_ADULT",
  REMOVE_CHILD = "REMOVE_CHILD",
  REMOVE_INFANT = "REMOVE_INFANT",
  ADD_START_DATE = "ADD_START_DATE",
  ADD_END_DATE = "ADD_END_DATE",
  SELECT_PROGRAM = "SELECT_PROGRAM",
  SELECT_ROOMS = "SELECT_ROOMS",
  ADD_PROGRAMS = "ADD_PROGRAMS",
  RESET_PROGRAMS = "RESET_PROGRAMS",
  ADD_ROOMS = "ADD_ROOMS",
  RESET_ROOMS = "RESET_ROOMS",
  ADD_USER_DATA = "ADD_USER_DATA",
  ADD_ROOM_CONFIG = "ADD_ROOM_CONFIG",
  ADD_COUPON = "ADD_COUPON"
}