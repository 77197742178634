import { Switch, TableCell, TableRow } from "@mui/material";
import { FC, useState } from "react";
import { ProgramDayRowProps } from "../../../../../types/shared";
import { useUserContext } from "../../../../context/UserContext";
import { BASE_URL } from "../../../../api/config";

const ProgramDayRow: FC<ProgramDayRowProps> = (props: ProgramDayRowProps) => {
  const {
    program_id,
    day_id,
    is_allowed_checkin: is_checkin_allowed,
    is_allowed_checkout: is_checkout_allowed,
    name,
  } = props;

  const { user } = useUserContext();
  const [isCheckinAllowed, setIsCheckinAllowed] = useState<boolean>(is_checkin_allowed);
  const [isCheckoutAllowed, setIsCheckoutAllowed] = useState<boolean>(is_checkout_allowed);

  // función para cuando cambie el switch acá.
  const updateProgramDay = (key: string, value: boolean, values: Record<string, any>) => {
    if (user) {
      fetch(BASE_URL + 'program-days', {
        body: JSON.stringify({ program_id, day_id, [key]: value, ...values }),
        method: 'PUT',
        headers: {
          'x-auth-token': user.token,
          'Content-Type': 'application/json'
        }
      }).then(response => {
        if(response.status === 200){
          if(key === 'is_checkin_allowed') {
            return setIsCheckinAllowed(prev => !prev)
          }
          setIsCheckoutAllowed(prev => !prev)
        }
      })
      .catch(e => console.log(e))
    }
  }

  console.log({
    props,
    isCheckinAllowed,
    isCheckoutAllowed
  })

  return (
    <TableRow>
      <TableCell>{name}</TableCell>
      <TableCell>
        <Switch
          checked={isCheckinAllowed}
          value={isCheckinAllowed}
          onClick={() => updateProgramDay('is_checkin_allowed', !isCheckinAllowed, {is_checkout_allowed: isCheckoutAllowed})}
        />
      </TableCell>
      <TableCell>
        <Switch
          checked={isCheckoutAllowed}
          value={isCheckoutAllowed}
          onClick={() => updateProgramDay('is_checkout_allowed', !isCheckoutAllowed, {is_checkin_allowed: isCheckinAllowed})}
        />
      </TableCell>
    </TableRow>
  )
}

export default ProgramDayRow;