import { ThemeProvider } from '@emotion/react';
import { alpha, Grid, Paper, Radio, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomStepper from '../../common/components/CustomStepper';
import Header from '../../common/components/Header';
import { useBookingContext } from '../../context/BookingContext';
import { StyledContainer } from '../step1/step1-styles';
import PersonalDataForm from './components/PersonalDataForm';
import Summary from './components/Summary';

const Step3: FC = () => {
  const { state } = useBookingContext();
  const navigate = useNavigate();

  useEffect(() => {
    if(!state.selectedProgram){
      navigate('/');
    }
  }, [navigate, state.selectedProgram])

  return (
    // Pasar esto a componente layout
    <StyledContainer maxWidth={false} disableGutters>
      <Header opacity={0.8} />
      <CustomStepper activeStep={2} />
      <Grid container px={4} pt={4} justifyContent="center" spacing={8}>
        <Grid item xs={12} sm={6} md={6}>
          <Summary />
        </Grid>
        <PersonalDataForm />
      </Grid>
    </StyledContainer >
  )
}

export default Step3;