import { createContext, FC, ReactNode, useContext, useState } from "react"

const SnackbarContext = createContext<{
  isOpen: boolean,
  message: string,
  showSnackBar: (open: boolean, message: string) => void
}>({
  isOpen: false,
  message: '',
  showSnackBar: (open: boolean, message: string) => null
});

const SnackbarContextProvider: FC<{ children: ReactNode }> = (props: { children: ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');

  const showSnackBar = (open: boolean, message: string) => {
    setIsOpen(open);
    setMessage(message);
  }

  return (
    <SnackbarContext.Provider value={{isOpen, message, showSnackBar}}>
      {props.children}
    </SnackbarContext.Provider>
  )
}

const useSnackbarContext = () => useContext(SnackbarContext);
export { SnackbarContextProvider, useSnackbarContext }