import { Grid, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import { FC, useEffect, useState } from "react"
import { RoomTypeProps } from "../../../../types/step2";
import { useBookingContext } from "../../../context/BookingContext";

const RoomSelection: FC<RoomTypeProps> = (props: RoomTypeProps) => {
  const { index, handleRoomConfiguration } = props;
  const [roomType, setRoomType] = useState('Matrimonial');

  const handleRoomTypeChange = (event: SelectChangeEvent) => {
    setRoomType(event.target.value);
    handleRoomConfiguration(index, event.target.value);
  };

  useEffect(() => {
    setRoomType('Matrimonial');
  }, [props.room])
  return (
    <Select
      fullWidth
      placeholder='Tipo'
      labelId="room-type"
      id="room-type-helper"
      value={roomType}
      label="Tipo de habitación"
      onChange={handleRoomTypeChange}
      disabled={props.disabled}
    >
      <MenuItem value="Matrimonial">Matrimonial</MenuItem>
      <MenuItem value="Twin">Camas separadas</MenuItem>
    </Select>
  )
}

export default RoomSelection;