import { Grid } from "@mui/material";
import { FC, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { SuccessProps } from "../../../types/payment";
import { BASE_URL } from "../../api/config";
import Header from "../../common/components/Header";
import Failed from "./components/Failed";
import Success from "./components/Success";
import { StyledContainer } from "./payment-summary-styles";

const PaymentSummary: FC = () => {
  const [searchParams] = useSearchParams();
  const [transaction, setTransaction] = useState<SuccessProps>();
  const [hasError, setHasError] = useState(false);

  const token = useMemo(() => searchParams.get("token_ws"), [searchParams]);

  useEffect(() => {
    if (token) {
      fetch(BASE_URL + "reservations/validate", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          token,
        }),
      })
        .then((data) => data.json())
        .then((response) => setTransaction(response))
        .catch(() => {
          setHasError(true);
        });
    }
  }, [token]);

  return (
    <StyledContainer maxWidth={false} disableGutters>
      <Header opacity={0.8} />
      <Grid
        container
        paddingX={2}
        alignItems="center"
        justifyContent="center"
        height="100%"
        width="100%"
        xs={12}
      >
        <Grid
          item
          container
          xs={12}
          sm={8}
          md={6}
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          minWidth="300px"
          maxHeight="100%"
          sx={{ backgroundColor: "white" }}
        >
          {transaction && transaction.isAuthorized && (
            <Success {...transaction} />
          )}
          {((transaction && transaction.isAuthorized === false) ||
            hasError) && <Failed />}
        </Grid>
      </Grid>
    </StyledContainer>
  );
};

export default PaymentSummary;
