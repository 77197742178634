import { IconButton, TableCell, TableRow } from "@mui/material";
import { FC, useState } from "react";
import { SpecialDayRowProps } from "../../../../../types/shared";
import { Delete } from "@mui/icons-material";
import { useUserContext } from "../../../../context/UserContext";
import { deleteSpecialDay } from "../../../../api/special-days";
import ConfirmModal from "../../../../common/components/ConfirmModal";


const SpecialDayRow: FC<SpecialDayRowProps> = (props: SpecialDayRowProps) => {
  const { user } = useUserContext();
  const {
    id,
    end_date,
    start_date,
    description,
    handleGetSpecialDays
  } = props;

  const [confirmModalState, SetConfirmModalState] = useState<boolean>(false);

  const handleDeleteSpecialDay = (id: number) => {
    if (user && id) {
      deleteSpecialDay(user.token, String(id))
        .then(() => {
          handleGetSpecialDays();
          SetConfirmModalState(false);
        })
        .catch(e => console.log(e))
    }
  }

  return (
    <>
      <ConfirmModal
        open={confirmModalState}
        onClose={() => SetConfirmModalState(false)}
        onConfirmClick={() => handleDeleteSpecialDay(id)}
      />
      <TableRow>
        <TableCell>{id}</TableCell>
        <TableCell>{description}</TableCell>
        <TableCell>{start_date}</TableCell>
        <TableCell>{end_date}</TableCell>
        <TableCell>
          <IconButton onClick={() => SetConfirmModalState(true)}><Delete fontSize="large" /></IconButton>
        </TableCell>
      </TableRow>
    </>

  )
}

export default SpecialDayRow;
