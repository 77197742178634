import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../../../../common/components/Title';
import { BASE_URL } from '../../../../api/config';
import { Button,  Grid, Paper, TableContainer } from '@mui/material';
import { Program, ProgramInput } from '../../../../../types/context';
import { useUserContext } from '../../../../context/UserContext';
import ProgramRow from './ProgramRow';
import ProgramModal from './ProgramModal';
import { getPrograms } from '../../../../api/programs';

// Acá sacar las habitaciones y su data, tener tres opciones, un dropdown por año, mes y un datepicker para búsqueda exacta
// Mostrar las habitaciones, la fecha y su disponibilidad en una tabla
// Habilitar un botón de edición para los elementos de la tabla

export default function Programs() {
  const { user } = useUserContext();
  const [programs, setPrograms] = useState<Array<Program>>([]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [newProgram, setNewProgram] = useState<ProgramInput>({
    rooms: [],
    program_days: [],
    is_active: true,
    hasAvailability: false,
    duration: 0,
    is_special_day: false,
  })

  const handleGetPrograms = (token: string) => {
    getPrograms(token)
      .then(response => setPrograms(response))
      .catch(e => console.log(e));
  }

  useEffect(() => {
    if (user) {
      handleGetPrograms(user.token);
    }
  }, [user])

  const handleNewProgram = (key: keyof typeof newProgram, value: string | number | boolean | string[]) => setNewProgram(
    prev => {
      if(key === 'program_days'){
        return({
          ...prev,
          program_days: typeof value === 'string' ? value.split(',') : value as string[],
        })
      }
      return  ({ ...prev, [key]: value })
    }
  )

  return (
    <>
      <ProgramModal 
        description={newProgram.description}
        details={newProgram.details}
        duration={newProgram.duration}
        handleChange={handleNewProgram}
        handleClose={handleClose}
        hasAvailability={newProgram.hasAvailability}
        image={newProgram.image}
        isOpen={open}
        is_active={newProgram.is_active}
        is_special_day={newProgram.is_special_day}
        name={newProgram.name}
        not_allowed={newProgram.not_allowed}
        program_days={newProgram.program_days}
        suggested={newProgram.suggested}
        handleReloadPrograms={handleGetPrograms}
      />
      <Grid
        display='flex'
        minWidth='100%'
        justifyContent='space-between'
        marginBottom={2}
      >
        <Title>Programas</Title>
        <Button
          variant='contained'
          onClick={handleOpen}
        >
          Nuevo Programa
        </Button>
      </Grid>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Nombre</TableCell>
              <TableCell>Activo</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {programs && programs.map((row) => (
              <ProgramRow program={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

    </>
  );
}