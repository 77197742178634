import { alpha, CardContent, Grid } from "@mui/material";
import { FC } from "react";
import { StyledDateFormCard } from "../date-selection";
import GuestForm from "./GuestForm";
import DatePicker from './DatePicker';

const DateForm: FC = () => {
  return (
    <Grid
      container
      columnGap={4}
      alignItems="center"
      justifyContent="center"
      justifyItems="center"
      padding={2}
      sx={{
        backgroundColor: alpha("#000", 0.6),
        borderRadius: "15px",
      }}
    >
      <Grid item xs={12} md={4} justifyContent="center" justifyItems="center">
        <DatePicker title="Entrada" type="start" />
      </Grid>
      <Grid itemProp="" xs={12} md={4}>
        <DatePicker title="Salida" type="end" />
      </Grid>
      <Grid item display="flex" flexDirection="column" xs={12} md={3}>
        <GuestForm />
      </Grid>
    </Grid>
  )
}

export default DateForm;