import { ThemeProvider } from "@emotion/react";
import { Step, StepLabel } from "@mui/material";
import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { StepperProps } from "../../../types/shared";
import calendarTheme from "../../themes/calendar";
import { StyledStepper } from "../styles/stepper";

const CustomStepper: FC<StepperProps> = (props: StepperProps) => {
  const { activeStep } = props;
  const navigate = useNavigate();
  const steps = [
    'ELEGIR PROGRAMA',
    'ELEGIR HABITACIÓN',
    'DATOS PERSONALES Y RESUMEN',
    'REALIZAR PAGO'
  ];

  const handleClick = useCallback((step: number) => {
    if (activeStep <= step) return;
    if (step === 0) {
      navigate('/programas');
    }

    if (step === 1) {
      navigate('/habitaciones');
    }

    if (step === 2) {
      navigate('/datos-personales');
    }
  }, [activeStep, navigate]);

  return (
    <ThemeProvider theme={calendarTheme}>
      <StyledStepper
        activeStep={activeStep}
        alternativeLabel
        sx={{display: {xs: 'none', sm: 'flex'}}}
      >
        {steps.map((label, index) => (
          <Step key={label} >
            <StepLabel onClick={() => handleClick(index)}>{label}</StepLabel>
          </Step>
        ))}
      </StyledStepper>
    </ThemeProvider>

  )
}

export default CustomStepper;