import { Grid, Typography } from "@mui/material";
import { FC } from "react";
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import YellowButton from "../../../common/components/YellowButton";
import { useNavigate } from "react-router-dom";

const Failed: FC = () => {
  const navigate = useNavigate();

  const handleSubmit = () => {
    navigate('/');
  }
  return (
    <>
      <Grid item xs={12} textAlign="center" marginBottom={2}>
        <CancelTwoToneIcon color="warning" sx={{ fontSize: "96px" }} />
      </Grid>
      <Grid item xs={12} textAlign="center" marginBottom={2}>
        <Typography color="error" variant="h5">No se ha podido concretar la reserva</Typography>
        <Typography variant="h6">Hubo un error en el pago, por favor vuelve a intentarlo</Typography>
      </Grid>
      <Grid item xs={12} textAlign="center" marginBottom={2}>
        <YellowButton text="Ir al inicio" handleSubmit={handleSubmit}/>
      </Grid>

    </>
  )
}

export default Failed;