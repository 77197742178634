import { IconButton, TableCell, TableRow } from "@mui/material";
import { FC, useState } from "react";
import { CouponRowProps } from "../../../../../types/shared";
import { Delete } from "@mui/icons-material";
import { useUserContext } from "../../../../context/UserContext";
import { BASE_URL } from "../../../../api/config";
import ConfirmModal from "../../../../common/components/ConfirmModal";


const CouponRow: FC<CouponRowProps> = (props: CouponRowProps) => {
  const { user } = useUserContext();
  const {
    id,
    code,
    discount,
    end_date,
    start_date,
    program_name,
    getCoupons
  } = props;

  const [confirmModalState, SetConfirmModalState] = useState<boolean>(false);

  const deleteCoupon = (id: number) => {
    if (user && id) {
      fetch(BASE_URL + 'coupons/' + id, {
        method: 'DELETE',
        headers: {
          'x-auth-token': user.token,
          'Content-Type': 'application/json'
        }
      }).then(response => response.json())
        .then(data => {
          getCoupons();
        })
        .catch(e => console.log(e))
    }
  }

  return (
    <>
      <ConfirmModal
        open={confirmModalState}
        onClose={() => SetConfirmModalState(false)}
        onConfirmClick={() => deleteCoupon(id)}
      />
      <TableRow>
        <TableCell>{id}</TableCell>
        <TableCell>{program_name}</TableCell>
        <TableCell>{code}</TableCell>
        <TableCell>{start_date}</TableCell>
        <TableCell>{end_date}</TableCell>
        <TableCell>{discount}</TableCell>
        <TableCell>
          <IconButton onClick={() => SetConfirmModalState(true)}><Delete fontSize="large" /></IconButton>
        </TableCell>
      </TableRow>
    </>

  )
}

export default CouponRow;
