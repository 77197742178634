import { ThemeProvider } from "@emotion/react";
import { FC } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Protected from "./common/components/Protected";
import { BookingContextProvider } from "./context/BookingContext";
import { SnackbarContextProvider } from "./context/SnackbarContext";
import Dashboard from "./pages/admin/dashboard/Dashboard";
import Programs from "./pages/admin/dashboard/programs/Programs";
import Reservations from "./pages/admin/dashboard/reservations/Reservations";
import Rooms from "./pages/admin/dashboard/rooms/Rooms";
import SignIn from "./pages/admin/sign-in/SignIn";
import DateSelection from "./pages/date-selection/DateSelection";
import PaymentSummary from "./pages/payments/PaymentSummary";
import Step1 from "./pages/step1/Step1";
import Step2 from "./pages/step2/Step2";
import Step3 from "./pages/step3/Step3";
import Step4 from "./pages/step4/Step4";
import Suggestions from "./pages/suggestions/Suggestions";
import textTheme from "./themes/text";
import Prices from "./pages/admin/dashboard/prices/Prices";
import Coupons from "./pages/admin/dashboard/coupons/Coupons";
import ProgramDays from "./pages/admin/dashboard/programs/ProgramDays";
import SpecialDays from "./pages/admin/dashboard/special-days/SpecialDays";
import ProgramAvailabilities from "./pages/admin/dashboard/program-availabilities/ProgramAvailabilities";

const App: FC = () => {
  return (
    <BookingContextProvider>
      <SnackbarContextProvider>
        <ThemeProvider theme={textTheme}>
          <Router>
            <Routes>
              <Route path='/' element={<DateSelection />} />
              <Route path='programas' element={<Step1 />} />
              <Route path='sugerencias' element={<Suggestions />} />
              <Route path='habitaciones' element={<Step2 />} />
              <Route path='datos-personales' element={<Step3 />} />
              <Route path='pago' element={<Step4 />} />
              <Route path='/pago/estado' element={<PaymentSummary />} />
              <Route path='admin/dashboard' element={<Protected><Dashboard><Reservations/></Dashboard></Protected>} />
              <Route path='admin/habitaciones' element={<Protected><Dashboard><Rooms/></Dashboard></Protected>} />
              <Route path='admin/programas' element={<Protected><Dashboard><Programs/></Dashboard></Protected>} />
              <Route path='admin/tarifas' element={<Protected><Dashboard><Prices /></Dashboard></Protected>} />
              <Route path='admin/cupones' element={<Protected><Dashboard><Coupons /></Dashboard></Protected>} />
              <Route path='admin/dias-por-programa' element={<Protected><Dashboard><ProgramDays /></Dashboard></Protected>} />
              <Route path='admin/feriados' element={<Protected><Dashboard><SpecialDays /></Dashboard></Protected>} />
              <Route path='admin/programas/disponibilidades' element={<Protected><Dashboard><ProgramAvailabilities /></Dashboard></Protected>} />
              <Route path='admin/login' element={<SignIn />} />
              <Route
                path="*"
                element={<Navigate to="/" replace />}
              />
            </Routes>
          </Router>
        </ThemeProvider>
      </SnackbarContextProvider>
    </BookingContextProvider>


  );
}

export default App;