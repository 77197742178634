export const days = [
  {
    id: '0',
    name: 'Lunes'
  },
  {
    id: '1',
    name: 'Martes'
  },
  {
    id: '2',
    name: 'Miércoles'
  },
  {
    id: '3',
    name: 'Jueves'
  },
  {
    id: '4',
    name: 'Viernes'
  },
  {
    id: '5',
    name: 'Sábado'
  },
  {
    id: '6',
    name: 'Domingo'
  },
]