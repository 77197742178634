import { BASE_URL } from "./config";

export const getProgramRooms = async (token: string, programId: number) => {
    const apiCall = await fetch(BASE_URL + 'program-rooms/program/' + programId, {
        headers: { 'x-auth-token': token }
    });

    const response = await apiCall.json();

    return response;
        
}

export const createProgramRoom = async (token: string, body: unknown) => {
    const apiCall = await fetch(BASE_URL + 'program-rooms', {
        headers: { 'x-auth-token': token, 'Content-Type': 'application/json' },
        method: "POST",
        body: JSON.stringify(body)
    })

    const response = await apiCall.json();
}

export const updateProgramRoom = async (token: string, body: unknown) => {
    const apiCall = await fetch(BASE_URL + 'program-rooms/', {
        headers: { 'x-auth-token': token, 'Content-Type': 'application/json' },
        method: "PUT",
        body: JSON.stringify(body)
    })

    const response = await apiCall.json();
}

export const deleteProgramRoom = async (token: string, body: unknown) => {
    const apiCall = await fetch(BASE_URL + 'program-rooms/', {
        headers: { 'x-auth-token': token, 'Content-Type': 'application/json' },
        method: "DELETE",
        body: JSON.stringify(body)
    })

    const response = await apiCall.json();
}