import { Box, Button, Grid, InputLabel, MenuItem, Modal, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import { DatePicker, LocalizationProvider, esES } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import Title from "../../../../common/components/Title"
import { useUserContext } from "../../../../context/UserContext"
import { FC, useEffect, useState } from "react"
import { Coupon, CouponInput, Program } from "../../../../../types/context"
import { BASE_URL } from "../../../../api/config"
import CouponRow from "./CouponRow"
import dayjs, { Dayjs } from "dayjs"

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  maxHeight: '90%',
  overflow: 'auto'
};

const Coupons: FC = () => {
  const { user } = useUserContext();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [coupons, setCoupons] = useState<Array<Coupon>>([]);
  const [programs, setPrograms] = useState<Array<Program>>([]);
  const [newCoupon, setNewCoupon] = useState<CouponInput>({
    code: '',
    start_date: dayjs().format("YYYY-MM-DD"),
    end_date: dayjs().format("YYYY-MM-DD"),
    discount: 0,

  });

  const handleNewCoupon = (key: keyof typeof newCoupon, value: number | string) => setNewCoupon(prev => ({ ...prev, [key]: value }))
  const getCoupons = () => {
    if (user) {
      fetch(BASE_URL + 'coupons', {
        headers: { 'x-auth-token': user.token }
      })
        .then(data => data.json())
        .then(response => setCoupons(response.coupons as Array<Coupon>));
    }
  }

  const createNewCoupon = () => {
    if (user && newCoupon.start_date && newCoupon.end_date && newCoupon.start_date <= newCoupon.end_date) {
      fetch(BASE_URL + 'coupons', {
        body: JSON.stringify(newCoupon),
        method: 'POST',
        headers: {
          'x-auth-token': user.token,
          'Content-Type': 'application/json'
        }
      }).then(response => response.json())
        .then(data => {
          handleClose();
          getCoupons();
        })
        .catch(e => console.log(e))
    }
  }

  useEffect(() => {
    getCoupons();
  }, [user])

  useEffect(() => {
    if (user) {
      fetch(BASE_URL + 'programs/all', {
        headers: { 'x-auth-token': user.token }
      })
        .then(data => data.json())
        .then(response => setPrograms(response as Array<Program>));
    }
  }, [user])

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h4" component="h2" marginBottom={4}>
            Nuevo Cupón
          </Typography>
          <Box
            component="form"
            display='flex'
            flexDirection='column'
            noValidate
            autoComplete="off"
          >
            <InputLabel id="programs-label">Seleccione un programa</InputLabel>
            <Select
              sx={{
                marginBottom: 8,
                width: "90%"
              }}
              labelId="programs-label"
              id="programs-dropdown"
              label="Programas"
              onChange={(e) => handleNewCoupon('program_id', Number(e.target.value))}
            >
              {programs && programs.length > 0 && programs.map(program => (<MenuItem value={program.id}>{program.name}</MenuItem>))}
            </Select>
            <Grid
              display='flex'
              justifyContent='start'
              columnGap={4}
            >
              <TextField
                required
                sx={{
                  width: "200px",
                  marginBottom: 4
                }}
                id="code"
                label="Código"
                placeholder='Ingrese Código'
                variant="standard"
                value={newCoupon.code}
                onChange={(e) => handleNewCoupon('code', e.target.value)}
              />
              <TextField
                required
                sx={{
                  width: "200px"
                }}
                id="discount"
                label="Descuento (Porcentaje)"
                placeholder='Ingrese porcentaje'
                variant="standard"
                value={newCoupon.discount}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  if (!Number.isNaN(value) && value <= 35) handleNewCoupon('discount', value)
                }}
              >%</TextField>

              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="es"
                localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
              >
                <DatePicker
                  label="Fecha Inicio"
                  value={dayjs(newCoupon.start_date)}
                  onChange={(newValue: Dayjs | null) => {
                    if (newValue) handleNewCoupon('start_date', newValue.format("YYYY-MM-DD"));
                  }}
                  renderInput={(params) => <TextField {...params} sx={{ marginRight: 4 }} />}
                  inputFormat="YYYY-MM-DD"
                  disablePast

                />
                <DatePicker
                  label="Fecha Fin"
                  value={dayjs(newCoupon.end_date)}
                  onChange={(newValue: Dayjs | null) => {
                    if (newValue) handleNewCoupon('end_date', newValue.format("YYYY-MM-DD"));
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  inputFormat="YYYY-MM-DD"
                  disablePast
                />
              </LocalizationProvider>

            </Grid>
            <Grid
              marginTop={4}
              display='flex'
              justifyContent='flex-start'
            >
              <Button
                variant='contained'
                onClick={createNewCoupon}
                style={{
                  width: '256px'
                }}
              >
                Crear
              </Button>
            </Grid>
          </Box>
        </Box>
      </Modal>

      <Grid
        display='flex'
        minWidth='100%'
        justifyContent='space-between'
        marginBottom={2}
        marginTop={2}
      >
        <Title>Cupones</Title>
        <Button
          variant='contained'
          onClick={handleOpen}
        >
          Nuevo Cupón
        </Button>
      </Grid>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Programa</TableCell>
              <TableCell>Código</TableCell>
              <TableCell>Fecha Inicio</TableCell>
              <TableCell>Fecha Fin</TableCell>
              <TableCell>Descuento (%)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {coupons && coupons.map(coupon => (<CouponRow
              id={coupon.id}
              code={coupon.code}
              discount={coupon.discount}
              start_date={coupon.start_date}
              end_date={coupon.end_date}
              program_name={programs.find(program => Number(program.id) === coupon.program_id)?.name || ''}
              getCoupons={getCoupons}
              key={`coupon-${coupon.id}-${coupon.code}`}
            />))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default Coupons;
