import { Box, Button, Grid, InputLabel, MenuItem, Modal, Paper, Select, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import { DatePicker, LocalizationProvider, esES } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import Title from "../../../../common/components/Title"
import { useUserContext } from "../../../../context/UserContext"
import { FC, useEffect, useState } from "react"
import { Coupon, CouponInput, Program, ProgramAvailability, ProgramAvailabilityInput } from "../../../../../types/context"
import { BASE_URL } from "../../../../api/config"
import ProgramAvailabilityRow from "./ProgramAvailabilityRow"
import dayjs, { Dayjs } from "dayjs"
import { createProgramAvailability, getAllProgramAvailabilities, getProgramAvailabilitiesByProgramId } from "../../../../api/program-availabilities"
import { getPrograms } from "../../../../api/programs"

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  maxHeight: '90%',
  overflow: 'auto'
};

const ProgramAvailabilities: FC = () => {
  const { user } = useUserContext();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [programAvailabilities, setProgramAvailabilities] = useState<Array<ProgramAvailability>>([]);
  const [programs, setPrograms] = useState<Array<Program>>([]);
  const [newProgramAvailability, setNewProgramAvailability] = useState<ProgramAvailabilityInput>({
    start: dayjs().format("YYYY-MM-DD"),
    end: dayjs().format("YYYY-MM-DD"),
  });

  const handleNewProgramAvailability = (key: keyof typeof newProgramAvailability, value: number | string) => setNewProgramAvailability(prev => ({ ...prev, [key]: value }))
  const handleGetProgramAvailabilities = () => {
    if (user && newProgramAvailability.program_id) {
      getProgramAvailabilitiesByProgramId(user.token, newProgramAvailability.program_id)
        .then(response => setProgramAvailabilities(response as Array<ProgramAvailability>));
    }
  }

  const handleCreateNewProgramAvailability = () => {
    if (user && newProgramAvailability.start && newProgramAvailability.end && newProgramAvailability.start <= newProgramAvailability.end) {
      createProgramAvailability(user.token, newProgramAvailability)
        .then(() => {
          handleClose();
          handleGetProgramAvailabilities();
        })
        .catch(e => console.log(e))
    }
  }

  const handleSelectedProgram = (e: SelectChangeEvent<string>) => {
    handleNewProgramAvailability('program_id', Number(e.target.value))
  };

  useEffect(() => {
    handleGetProgramAvailabilities();
  }, [user, newProgramAvailability.program_id])

  useEffect(() => {
    if (user) {
      getPrograms(user.token)
        .then(response => setPrograms(response as Array<Program>));
    }
  }, [user])

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h4" component="h2" marginBottom={4}>
            Nueva disponibilidad
          </Typography>
          <Box
            component="form"
            display='flex'
            flexDirection='column'
            noValidate
            autoComplete="off"
          >
            <InputLabel id="programs-label">Seleccione un programa</InputLabel>
            <Select
              sx={{
                marginBottom: 8,
                width: "90%"
              }}
              labelId="programs-label"
              id="programs-dropdown"
              label="Programas"
              onChange={(e) => handleNewProgramAvailability('program_id', Number(e.target.value))}
            >
              {programs && programs.length > 0 && programs.map(program => (<MenuItem value={program.id}>{program.name}</MenuItem>))}
            </Select>
            <Grid
              display='flex'
              justifyContent='start'
              columnGap={4}
            >
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="es"
                localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
              >
                <DatePicker
                  label="Fecha Inicio"
                  value={dayjs(newProgramAvailability.start)}
                  onChange={(newValue: Dayjs | null) => {
                    if (newValue) handleNewProgramAvailability('start', newValue.format("YYYY-MM-DD"));
                  }}
                  renderInput={(params) => <TextField {...params} sx={{ marginRight: 4 }} />}
                  inputFormat="YYYY-MM-DD"
                  disablePast

                />
                <DatePicker
                  label="Fecha Fin"
                  value={dayjs(newProgramAvailability.end)}
                  onChange={(newValue: Dayjs | null) => {
                    if (newValue) handleNewProgramAvailability('end', newValue.format("YYYY-MM-DD"));
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  inputFormat="YYYY-MM-DD"
                  disablePast
                />
              </LocalizationProvider>

            </Grid>
            <Grid
              marginTop={4}
              display='flex'
              justifyContent='flex-start'
            >
              <Button
                variant='contained'
                onClick={handleCreateNewProgramAvailability}
                style={{
                  width: '256px'
                }}
              >
                Crear
              </Button>
            </Grid>
          </Box>
        </Box>
      </Modal>
      <Grid
        display='flex'
        minWidth='100%'
        justifyContent='space-between'
        marginBottom={2}
        marginTop={2}
      >
        <Title>Disponibilidad de programas</Title>
        <Button
          variant='contained'
          onClick={handleOpen}
        >
          Nueva Disponibilidad
        </Button>
      </Grid>
      <InputLabel id="programs-label">Seleccione un programa</InputLabel>
      <Select
        sx={{
          marginBottom: "24px",
          width: "90%"
        }}
        labelId="programs-label"
        id="programs-dropdown"
        label="Programas"
        onChange={handleSelectedProgram}
      >
        {programs && programs.length > 0 && programs.map(program => (<MenuItem value={program.id}>{program.name}</MenuItem>))}
      </Select>
      {newProgramAvailability.program_id && (
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Nombre</TableCell>
                <TableCell>Fecha Inicio</TableCell>
                <TableCell>Fecha Fin</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {programAvailabilities && programAvailabilities.length > 0 && programAvailabilities.map(programAvailability => (<ProgramAvailabilityRow
                program_id={programAvailability.program_id}
                id={programAvailability.id}
                start={programAvailability.start}
                end={programAvailability.end}
                program_name={programs.find(program => Number(program.id) === programAvailability.program_id)?.name || ''}
                handleGetProgramAvailability={handleGetProgramAvailabilities}
                key={`pa-${programAvailability.program_id}-${programAvailability.start}-${programAvailability.end}`}
              />))}
            </TableBody>
          </Table>
        </TableContainer>
      )
      }
    </>
  )
}

export default ProgramAvailabilities;
