import { InputLabel, MenuItem, Select } from "@mui/material";
import { FC } from "react";
import { RoomDropdownProps } from "../../../types/shared";

const RoomDropdown: FC<RoomDropdownProps> = (props: RoomDropdownProps) => {
  const { 
    rooms,
    handleSelection
  } = props;
  return (
    <div style={{width: "300px"}}>
      <InputLabel id="rooms-label">Elegir Habitación</InputLabel>
      <Select
        sx={{
          marginBottom: "24px",
          width: "90%"
        }}
        labelId="rooms-label"
        id="rooms-dropdown"
        label="Habitación"
        onChange={handleSelection}
      >
        {rooms && rooms.length > 0 && rooms.map(room => (<MenuItem value={room.id}>{room.name}</MenuItem>))}
      </Select>
    </div>
  )
}

export default RoomDropdown;
