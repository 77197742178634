import { Button, Grid, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import DateField from '@mui/x-date-pickers/DateField';
import { FC, useCallback, useEffect, useState } from "react";
import { BASE_URL } from "../../../../api/config";
import { PriceInput, Program, ProgramRoom, Room } from "../../../../../types/context";
import Title from "../../../../common/components/Title";
import { useUserContext } from "../../../../context/UserContext";
import PriceRow from "./PriceRow";
import ConfirmModal from "../../../../common/components/ConfirmModal";
import { deleteProgramRoom, getProgramRooms } from "../../../../api/program-rooms";
import PriceModal from "./PriceModal";
import { getPrograms } from "../../../../api/programs";

const Prices: FC = () => {
  const { user } = useUserContext();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [rooms, setRooms] = useState<Array<Room>>([]);
  const [programRooms, setProgramRooms] = useState<Array<ProgramRoom>>([])
  const [programs, setPrograms] = useState<Array<Program>>([]);
  const [selectedProgram, setSelectedProgram] = useState<number>();
  const [confirmModalState, SetConfirmModalState] = useState<boolean>(false);
  const [currentPriceRow, setCurrentPriceRow] = useState<{
    room_id?: number,
    program_id?: number,
    start_date?: string,
    end_date?: string
  }>();
  const [newPriceData, setNewPriceData] = useState<PriceInput>({ single_price: 0, double_price: 0, additional_price: 0, start_date: new Date().toDateString(), end_date: new Date().toDateString() });
  const handleNewPrice = (key: keyof typeof newPriceData, value: number | string) => setNewPriceData(prev => ({ ...prev, [key]: value }))
  const handleSelectPriceRow = (room_id: number, program_id: number, start_date: string, end_date: string) => {
    setCurrentPriceRow({
      room_id,
      program_id,
      start_date,
      end_date
    });
    SetConfirmModalState(true);

  }

  const handleProgramRooms = () => {
    if (user && selectedProgram) {
      getProgramRooms(user.token, selectedProgram)
        .then(data => setProgramRooms(data.programRooms))
        .catch(err => console.log(err))
    }
  }

  const handleDeletePrice = useCallback(async () => {
    if (user && selectedProgram) {
      await deleteProgramRoom(
        user?.token || '',
        {
          room_id: currentPriceRow?.room_id,
          program_id: currentPriceRow?.program_id,
          start_date: currentPriceRow?.start_date,
          end_date: currentPriceRow?.end_date
        }
      );
      handleProgramRooms();
      SetConfirmModalState(false);
    }
  }, [currentPriceRow])
  
  const handleSelectedProgram = (e: SelectChangeEvent<string>) => {
    setSelectedProgram(Number(e.target.value))
    handleNewPrice('program_id', Number(e.target.value))
  };

  useEffect(() => {
    if (user) {
      getPrograms(user.token)
        .then(response => setPrograms(response as Array<Program>));
    }
  }, [user])

  useEffect(() => {
    fetch(BASE_URL + 'rooms')
      .then(response => response.json())
      .then(data => setRooms(data.rooms))
      .catch(err => console.log(err))
  }, []);

  useEffect(() => {
    if (selectedProgram && user) {
      handleProgramRooms();
    }
  }, [selectedProgram])

  return (
    <>
      <ConfirmModal
        open={confirmModalState}
        onClose={() => SetConfirmModalState(false)}
        onConfirmClick={handleDeletePrice}
      />
      <PriceModal
        open={open}
        handleClose={handleClose}
        handleNewPrice={handleNewPrice}
        reloadProgramRooms={handleProgramRooms}
        rooms={rooms}
        additional_price={newPriceData.additional_price}
        double_price={newPriceData.double_price}
        start_date={newPriceData.start_date}
        end_date={newPriceData.end_date}
        program_id={selectedProgram}
        room_id={newPriceData.room_id}
        single_price={newPriceData.single_price}
      />
      <InputLabel id="programs-label">Seleccione un programa</InputLabel>
      <Select
        sx={{
          marginBottom: "24px",
          width: "90%"
        }}
        labelId="programs-label"
        id="programs-dropdown"
        label="Programas"
        onChange={handleSelectedProgram}
      >
        {programs && programs.length > 0 && programs.map(program => (<MenuItem value={program.id}>{program.name}</MenuItem>))}
      </Select>
      {selectedProgram && (<>
        <Grid
          display='flex'
          minWidth='100%'
          justifyContent='space-between'
          marginBottom={2}
          marginTop={2}
        >
          <Title>Tarifas</Title>
          <Button
            variant='contained'
            onClick={handleOpen}
          >
            Nueva Tarifa
          </Button>
        </Grid>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Habitación</TableCell>
                <TableCell>Single</TableCell>
                <TableCell>Double</TableCell>
                <TableCell>Adicional</TableCell>
                <TableCell>Fecha inicio</TableCell>
                <TableCell>Fecha término</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {programRooms && programRooms.length > 0 && (
                programRooms.map(programRoom => (
                  <PriceRow
                    room_id={programRoom.room_id}
                    program_id={programRoom.program_id}
                    single_price={programRoom.single_price}
                    double_price={programRoom.double_price}
                    additional_price={programRoom.additional_price}
                    start_date={programRoom.start_date as string}
                    end_date={programRoom.end_date as string}
                    rooms={rooms}
                    deletePrice={handleSelectPriceRow}
                    reloadProgramRooms={handleProgramRooms}
                  />))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </>)}
    </>

  )
}

export default Prices;
