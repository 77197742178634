import { IconButton, TableCell, TableRow } from "@mui/material";
import { FC, useState } from "react";
import { CouponRowProps, ProgramAvailabilityRowProps } from "../../../../../types/shared";
import { Delete } from "@mui/icons-material";
import { useUserContext } from "../../../../context/UserContext";
import { BASE_URL } from "../../../../api/config";
import { deleteProgramAvailability } from "../../../../api/program-availabilities";
import ConfirmModal from "../../../../common/components/ConfirmModal";


const ProgramAvailabilityRow: FC<ProgramAvailabilityRowProps> = (props: ProgramAvailabilityRowProps) => {
  const { user } = useUserContext();
  const {
    end,
    handleGetProgramAvailability,
    program_id,
    program_name,
    start,
    id
  } = props;

  const [confirmModalState, SetConfirmModalState] = useState<boolean>(false);

  const handleDeleteProgramAvailability = (id: number) => {
    if (user && id) {
      deleteProgramAvailability(user.token, id)
        .then(() => {
          handleGetProgramAvailability();
          SetConfirmModalState(false);
        })
        .catch(e => console.log(e))
    }
  }

  return (
    <>
      <ConfirmModal
        open={confirmModalState}
        onClose={() => SetConfirmModalState(false)}
        onConfirmClick={() => handleDeleteProgramAvailability(id)}
      />
      <TableRow>
        <TableCell>{program_name}</TableCell>
        <TableCell>{start}</TableCell>
        <TableCell>{end}</TableCell>
        <TableCell>
          <IconButton onClick={() => SetConfirmModalState(true)}><Delete fontSize="large" /></IconButton>
        </TableCell>
      </TableRow>
    </>

  )
}

export default ProgramAvailabilityRow;
