import { Grid } from "@mui/material";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Program } from "../../../types/context";
import CustomStepper from "../../common/components/CustomStepper";
import Header from "../../common/components/Header";
import { useBookingContext } from "../../context/BookingContext";
import { BookingContextActionEnum } from "../../enum";
import { StyledContainer } from "./step1-styles";
import ProgramCard from "./components/ProgramCard";
import { BASE_URL } from "../../api/config";
import { useSnackbarContext } from "../../context/SnackbarContext";

const Step1: FC = () => {
  const { state, dispatch } = useBookingContext();
  const { showSnackBar, isOpen, message } = useSnackbarContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      (!state.startDate && !state.endDate) ||
      (state.endDate &&
        Math.ceil(state.endDate.diff(state.startDate, "d", true)) === 0)
    ) {
      navigate("/");
    }
  }, [navigate, state.endDate, state.startDate]);

  useEffect(() => {
    dispatch({
      type: BookingContextActionEnum.ADD_COUPON,
      payload: undefined,
    });
  }, []);

  const handleSubmit = (program: Program) => {
    if (state.startDate && state.endDate) {
      const startDate = state.startDate.format("YYYY-MM-DD");
      const endDate = state.endDate.format("YYYY-MM-DD");
      const query =
        `?startDate=${startDate}&endDate=${endDate}` +
        `&adults=${state.guests.adults}&children=${state.guests.children}&infants=${state.guests.infants}`;
      fetch(BASE_URL + "rooms/" + program.id + query, {
        method: "GET",
      })
        .then((data) => {
          if (data.status === 404) {
            throw new Error("No availability");
          }
          return data.json();
        })
        .then((response) => {
          if (Object.keys(response).length === 0) {
            showSnackBar(
              true,
              "No contamos con disponibilidad para la fecha solicitada, por favor seleccionar otra fecha o contactarse a reservas +56224111720 / reservas@jahuel.cl"
            );
            navigate("/");
          }
          dispatch({
            type: BookingContextActionEnum.ADD_ROOMS,
            payload: {
              rooms: response,
            },
          });
          dispatch({
            type: BookingContextActionEnum.SELECT_PROGRAM,
            payload: {
              selectedProgram: program,
            },
          });
          navigate("/habitaciones");
        })
        .catch((e) => {
          showSnackBar(
            true,
            "No contamos con disponibilidad para la fecha solicitada, por favor seleccionar otra fecha o contactarse a reservas +56224111720 / reservas@jahuel.cl"
          );
          navigate("/");
        });
    }
  };

  return (
    // Pasar esto a componente layout
    <StyledContainer maxWidth={false} disableGutters>
      <Header opacity={0.8} />
      <CustomStepper activeStep={0} />
      <Grid container padding={4} justifyContent="center">
        {state.programs &&
          state.programs.map((program, index) => (
            <Grid item xs={12} sm={4} md={3} margin={4} marginTop={1}>
              <ProgramCard
                program={program}
                title={program.name}
                handleSubmit={handleSubmit}
              />
            </Grid>
          ))}
      </Grid>
    </StyledContainer>
  );
};

export default Step1;
