import { createTheme } from "@mui/material";
import { green, yellow } from "@mui/material/colors";

const calendarTheme = createTheme({
  palette: {
    primary: {
      main: "#F2C94C",
    },
    secondary: {
      main: "#000",
    },
    success: {
      main: green[500],
    },
  },
  typography: {
    fontFamily: 'Source Sans Pro,sans-serif',
  }
});

export default calendarTheme;