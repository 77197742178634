import { FC } from 'react';
import { RemoveCircle, AddCircle } from '@mui/icons-material';
import { Grid, IconButton, Typography } from '@mui/material';
import { AddOrSubstractInputProps } from '../../../../types/step1';

const AddOrSubstractInput: FC<AddOrSubstractInputProps> = (props: AddOrSubstractInputProps) => {
  const { handleAdd, handleRemove, counter, max } = props;
  return (
    <Grid container maxWidth={68} >
      <Grid item xs={4} justifyContent="center">
        <IconButton
          onClick={handleRemove}
          disabled={counter < 1}
          sx={{
            margin: 0,
            padding: 0
          }}
        >
          <RemoveCircle color="primary" />
        </IconButton>
      </Grid>
      <Grid item xs={4} justifyContent="center">
        <Typography textAlign="center" color="primary"> {counter} </Typography>
      </Grid>
      <Grid item xs={4} justifyContent="center">
        <IconButton
          onClick={handleAdd}
          disabled={counter >= max}
          sx={{
            margin: 0,
            padding: 0
          }}
        >
          <AddCircle color="primary" />
        </IconButton>
      </Grid>
    </Grid>
  )
}

export default AddOrSubstractInput;