import { createTheme } from "@mui/material";
import { green, orange, red } from "@mui/material/colors";

const textTheme = createTheme({
  palette: {
    primary: {
      main: "#fff",
    },
    secondary: {
      main: "#000",
    },
    success: {
      main: green[500],
    },
  },
  typography: {
    fontFamily: 'Source Sans Pro,sans-serif'
  }
});

export default textTheme;