import { Box, Button, Grid, Modal, SelectChangeEvent, TextField, Typography } from "@mui/material"
import { style } from "../../../../common/styles/modal"
import RoomDropdown from "../../../../common/components/RoomDropdown"
import { DatePicker, LocalizationProvider, esES } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { useUserContext } from "../../../../context/UserContext"
import { createProgramRoom, updateProgramRoom } from "../../../../api/program-rooms"
import { PriceInput, Room } from "../../../../../types/context"
import { FC } from "react"

type PriceModalProps = {
    open: boolean,
    handleClose: () => void,
    program_id?: number,
    room_id?: number,
    single_price?: number,
    double_price?: number,
    additional_price?: number,
    start_date?: string,
    end_date?: string,
    handleNewPrice: (key: keyof PriceInput, value: string | number) => void,
    reloadProgramRooms: (token: string) => void,
    rooms: Array<Room>,
    isEditing?: boolean,
}

const PriceModal: FC<PriceModalProps> = (props: PriceModalProps) => {
    const {
        open,
        handleClose,
        program_id,
        room_id,
        single_price,
        double_price,
        additional_price,
        start_date,
        end_date,
        handleNewPrice,
        reloadProgramRooms,
        rooms,
        isEditing,
    } = props;

    const { user } = useUserContext();
    const handleSelectedRoom = (e: SelectChangeEvent<string>) => handleNewPrice('room_id', Number(e.target.value));

    const handleCreateOrUpdatePrice = () => {
        if (user) {
            const newProgramRoom = {
                program_id,
                room_id,
                single_price,
                double_price,
                additional_price,
                start_date,
                end_date
            }

            const action = isEditing
                ? updateProgramRoom(user.token, newProgramRoom)
                : createProgramRoom(user.token, newProgramRoom);

            action.then(() => {
                handleClose();
                reloadProgramRooms?.(user.token);
            })
                .catch(e => console.log(e))
        }
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h4" component="h2" marginBottom={4}>
                    {isEditing ? 'Editar Tarifa' : 'Nueva Tarifa'}
                </Typography>
                <Box
                    component="form"
                    display='flex'
                    flexDirection='column'
                    noValidate
                    autoComplete="off"
                >
                    {!isEditing && <RoomDropdown rooms={rooms} handleSelection={handleSelectedRoom} />}
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <TextField
                            required
                            sx={{
                                width: "200px",
                            }}
                            id="single_price"
                            label="Precio Single"
                            placeholder='Precio Single'
                            variant="standard"
                            value={single_price}
                            onChange={(e) => { if (!Number.isNaN(Number(e.target.value))) handleNewPrice('single_price', Number(e.target.value)) }}
                        />
                        <TextField
                            required
                            sx={{
                                width: "200px"
                            }}
                            id="double_price"
                            label="Precio Double"
                            placeholder='Precio Double'
                            variant="standard"
                            value={double_price}
                            onChange={(e) => { if (!Number.isNaN(Number(e.target.value))) handleNewPrice('double_price', Number(e.target.value)) }}
                        />
                        <TextField
                            required
                            sx={{
                                width: "200px"
                            }}
                            id="additional_price"
                            label="Precio Adicional"
                            placeholder='Precio Adicional'
                            variant="standard"
                            value={additional_price}
                            onChange={(e) => { if (!Number.isNaN(Number(e.target.value))) handleNewPrice('additional_price', Number(e.target.value)) }}
                        />
                    </div>
                    {!isEditing && <div style={{ marginTop: 52 }}>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="es"
                            localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
                        >
                            <DatePicker
                                label="Fecha Inicio"
                                value={start_date}
                                onChange={(newValue?: string | null) => {
                                    if (newValue) handleNewPrice('start_date', newValue);
                                }}
                                renderInput={(params) => <TextField {...params} sx={{ marginRight: 8 }} />}
                                inputFormat="YYYY-MM-DD"
                                disablePast

                            />
                            <DatePicker
                                label="Fecha Fin"
                                value={end_date}
                                onChange={(newValue?: string | null) => {
                                    if (newValue) handleNewPrice('end_date', newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                                inputFormat="YYYY-MM-DD"
                                disablePast
                            />
                        </LocalizationProvider>
                    </div>}
                    <Grid
                        marginTop={2}
                        display='flex'
                        justifyContent='center'
                    >
                        <Button
                            variant='contained'
                            onClick={handleCreateOrUpdatePrice}
                            style={{
                                width: '50%'
                            }}
                        >
                            {isEditing ? 'Editar' : 'Crear'}
                        </Button>
                    </Grid>
                </Box>
            </Box>
        </Modal>
    )
}

export default PriceModal;