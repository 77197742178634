import { Card, Grid, MenuItem, Paper, Radio, Select, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomStepper from '../../common/components/CustomStepper';
import Header from '../../common/components/Header';
import YellowButton from '../../common/components/YellowButton';
import { useBookingContext } from '../../context/BookingContext';
import { BookingContextActionEnum } from '../../enum';
import { StyledContainer } from '../step1/step1-styles';
import RoomSelection from './components/RoomSelection';

const Step2: FC = () => {
  const { state, dispatch } = useBookingContext();

  const [selectedValue, setSelectedValue] = useState<string>();
  const [roomConfiguration, setRoomConfiguration] = useState<Array<string>>([]);
  const navigate = useNavigate();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const handleRoomConfiguration = (index: number, value: string) => {
    setRoomConfiguration((prev: Array<string>) => {
      const curr = prev;
      curr[index] = value;
      return curr;
    })
  }

  const selectedRooms = useMemo(() => {
    if (selectedValue) {
      return state.rooms[Number(selectedValue)];
    }
  }, [selectedValue, state.rooms]);

  const length = useMemo(() => {
    if (state.startDate && state.endDate) {
      return Math.ceil(state.endDate.diff(state.startDate, 'd', true));
    }
    return 0;
  }, [state.endDate, state.startDate])

  useEffect(() => {
    if (selectedRooms) {
      setRoomConfiguration(selectedRooms.map(() => 'Matrimonial'));
    }
  }, [selectedRooms])

  useEffect(() => {
    if (!state.selectedProgram) {
      navigate('/');
    }
  }, [navigate, state.selectedProgram])

  const handleSubmit = () => {
    if (selectedRooms) {
      dispatch({
        type: BookingContextActionEnum.SELECT_ROOMS,
        payload: {
          selectedRooms
        }
      })
      dispatch({
        type: BookingContextActionEnum.ADD_ROOM_CONFIG,
        payload: {
          room_configuration: roomConfiguration.join(',')
        }
      })
      navigate('/datos-personales');
    }
  }

  return (
    // Pasar esto a componente layout
    <StyledContainer maxWidth={false} disableGutters>
      <Header opacity={0.8} />
      <CustomStepper activeStep={1} />
      <Grid container justifyContent="space-evenly" py={4}>
        <Grid item xs={10} sm={5} marginBottom={4} >
          <img
            alt="habitación"
            src={`/images/${(selectedRooms && selectedRooms[0]?.image) || 'habitacion-clasica.jpg'}`}
            width="100%"
          />
          <TableContainer  component={Paper} sx={{ width: "96%", marginTop: "4px", pl: "12px" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography fontWeight={600}>Noches</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontWeight={600}>Check In</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontWeight={600}>Check Out</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography>{Math.ceil(state.endDate!.diff(state.startDate!, 'd'))}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontWeight={600}>{state.startDate?.format("YYYY-MM-DD")}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontWeight={600}>{state.endDate?.format("YYYY-MM-DD")}</Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item container xs={10} sm={5} >
          <Grid item xs={12}>
            <TableContainer component={Paper} sx={{ pl: "12px" }}>
              <Table aria-label="room-selection-table" >
                <TableHead>
                  <TableRow >
                    <TableCell align='left' width={300}>
                      <Typography fontWeight="bold">Opciones disponibles</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography fontWeight="bold">Precio (IVA incl.)</Typography>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    state.rooms && Object.values(state.rooms).map(room => {
                      if (room.length > 0) {
                        let name = `${room.length} ${room.length > 1 ? room[0].pluralName : room[0].name}`;
                        if (room.find(r => r.hasExtraBed)) {
                          const extraBeds = room.reduce((prev, curr) => {
                            if (curr.hasExtraBed) {
                              prev += 1;
                            }
                            return prev;
                          }, 0)
                          name += ` + ${extraBeds} ${extraBeds > 1 ? 'Camas adicionales' : 'Cama adicional'}`;
                        }
                        const price = room.reduce((prev, curr) => prev += curr.price, 0) * (length);
                        const additionalPrice = room.reduce((prev, curr) => prev += curr.additionalBedPrice, 0) * (length);
                        const id = room[0].id;
                        return (<TableRow>
                          <TableCell sx={{ padding: "20px" }}>
                            <Typography>{name}</Typography>
                          </TableCell>
                          <TableCell sx={{ padding: "8px" }} >
                            <Typography>${new Intl.NumberFormat('es-CL').format(price + additionalPrice)}</Typography>
                          </TableCell>
                          <TableCell sx={{ padding: "8px" }} >
                            <Radio
                              checked={selectedValue === String(id)}
                              onChange={handleChange}
                              value={id}
                              sx={{
                                '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)':
                                {
                                  color: '#F3C702',
                                },
                                '& .MuiSvgIcon-root + .MuiSvgIcon-root': {
                                  color: '#FCD111',
                                },
                              }}
                            ></Radio>
                          </TableCell>
                        </TableRow>)
                      }
                    })
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer component={Paper} sx={{ pl: "12px", marginTop: "10px" }} >
              <Table aria-label="room-selection-table" >
                <TableHead>
                  <TableRow >
                    <TableCell align='left' width={300}>
                      <Typography fontWeight="bold">Opciones disponibles</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography fontWeight="bold">Tipo</Typography>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    selectedRooms && selectedRooms.map((room, index) => {
                      return (<TableRow>
                        <TableCell sx={{ padding: "20px" }}>
                          <Typography>{`${room.name} ${index + 1}`}</Typography>
                        </TableCell>
                        <TableCell sx={{ padding: "8px" }} >
                          <RoomSelection
                            disabled={!room.canSelectConfiguration}
                            room={selectedValue}
                            index={index}
                            handleRoomConfiguration={handleRoomConfiguration}
                          />
                        </TableCell>
                      </TableRow>)
                    })
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="end" marginTop={4}>
            <YellowButton handleSubmit={handleSubmit} text="Siguiente" disabled={!selectedRooms} />
          </Grid>
        </Grid>
      </Grid>
    </StyledContainer >
  )
}

export default Step2;