import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import { FC, useMemo, useState } from "react";
import { RoomRowProps } from "../../../../../types/dashboard";
import { BASE_URL } from "../../../../api/config";
import { useUserContext } from "../../../../context/UserContext";

const RoomRow: FC<RoomRowProps> = (props: RoomRowProps) => {
  const { room } = props;
  const { user } = useUserContext();
  const [currentAvailability, setCurrentAvailability] = useState({
    date: room.room_availabilities?.[0]?.availability_id,
    availability: room.room_availabilities?.[0]?.available_rooms,
  });
  const [isEditing, setIsEditing] = useState(false);
  const dates = useMemo(() => {
    if (room.room_availabilities) {
      return room.room_availabilities.map((ra) => ra.availability_id);
    }
  }, [room.room_availabilities]);

  const handleCurrentAvailability = (event: SelectChangeEvent) => {
    if (room.room_availabilities) {
      setCurrentAvailability((prev) => ({
        date: event.target.value,
        availability: room.room_availabilities!.find(
          (ra) => ra.availability_id === event.target.value
        )?.available_rooms,
      }));
    }
  };

  const handleChangeCurrentAvailability = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!isNaN(Number(event.target.value))) {
      setCurrentAvailability((prev) => ({
        ...prev,
        availability: Number(event.target.value),
      }));
    }
  };

  const save = () => {
    if (user) {
      setIsEditing(false);
      fetch(BASE_URL + "room-availabilities/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": user.token,
        },
        body: JSON.stringify({
          room_id: room.id,
          availability_id: currentAvailability.date,
          available_rooms: currentAvailability.availability,
        }),
      })
        .then((data) => data.json())
        .then((response) => response)
        .catch((err) => console.log(err));
    }
  };

  return (
    <TableRow key={`${room.id}`}>
      <TableCell>{room.name}</TableCell>
      <TableCell>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="demo-select-small">Fecha</InputLabel>
          <Select
            labelId="demo-select-small"
            id="demo-select-small"
            value={currentAvailability.date}
            label="Fecha"
            onChange={handleCurrentAvailability}
          >
            {dates &&
              dates.map((date) => <MenuItem value={date}>{date}</MenuItem>)}
          </Select>
        </FormControl>
      </TableCell>
      <TableCell sx={{ textAlign: "left" }}>
        <TextField
          id="standard-basic"
          variant="outlined"
          size="small"
          disabled={!isEditing}
          value={currentAvailability.availability}
          onChange={handleChangeCurrentAvailability}
          inputProps={{
            readOnly: !isEditing,
          }}
        />
      </TableCell>
      <TableCell width={200}>
        {isEditing ? (
          <Button color="info" variant="contained" onClick={save}>
            Guardar
          </Button>
        ) : (
          <Button onClick={() => setIsEditing(true)}>Editar</Button>
        )}
      </TableCell>
    </TableRow>
  );
};

export default RoomRow;
