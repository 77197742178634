import dayjs from "dayjs";
import { createContext, FC, useContext, useReducer } from "react";
import { BookingContextAction, BookingContextState, ContextProps } from '../../types/context';
import { BookingContextActionEnum } from "../enum";

const initialData: BookingContextState = {
  guests: {
    adults: 0,
    children: 0,
    infants: 0
  },
  startDate: dayjs(),
  endDate: dayjs(),
  programs: [],
  rooms: [],
  room_configuration: '',
  userData: {
    name: '',
    email: '',
    phone: ''
  },
}

const BookingContext = createContext<
  {
    state: BookingContextState,
    dispatch: React.Dispatch<BookingContextAction>
  }>({
    state: initialData,
    dispatch: () => null
  });

const handleAction = (state: BookingContextState, action: BookingContextAction) => {
  switch (action.type) {
    case BookingContextActionEnum.ADD_ADULT:
      return {
        ...state,
        guests: {
          ...state.guests,
          adults: state.guests.adults + 1
        }
      }
    case BookingContextActionEnum.REMOVE_ADULT:
      return {
        ...state,
        guests: {
          ...state.guests,
          adults: state.guests.adults - 1
        }
      }
    case BookingContextActionEnum.ADD_CHILD:
      return {
        ...state,
        guests: {
          ...state.guests,
          children: state.guests.children + 1
        }
      }
    case BookingContextActionEnum.REMOVE_CHILD:
      return {
        ...state,
        guests: {
          ...state.guests,
          children: state.guests.children - 1
        }
      }
    case BookingContextActionEnum.ADD_INFANT:
      return {
        ...state,
        guests: {
          ...state.guests,
          infants: state.guests.infants + 1
        }
      }
    case BookingContextActionEnum.REMOVE_INFANT:
      return {
        ...state,
        guests: {
          ...state.guests,
          infants: state.guests.infants - 1
        }
      }
    case BookingContextActionEnum.SELECT_PROGRAM:
      return {
        ...state,
        selectedProgram: action.payload?.selectedProgram
      }
    case BookingContextActionEnum.SELECT_ROOMS:
      return {
        ...state,
        selectedRooms: action.payload?.selectedRooms
      }
    case BookingContextActionEnum.ADD_START_DATE:
      return {
        ...state,
        startDate: action.payload?.date
      }
    case BookingContextActionEnum.ADD_END_DATE:
      return {
        ...state,
        endDate: action.payload?.date
      }
    case BookingContextActionEnum.ADD_PROGRAMS:
      return {
        ...state,
        programs: action.payload?.programs || []
      }
    case BookingContextActionEnum.RESET_PROGRAMS:
      return {
        ...state,
        programs: []
      }
    case BookingContextActionEnum.ADD_ROOMS:
      return {
        ...state,
        rooms: action.payload?.rooms || []
      }
    case BookingContextActionEnum.RESET_ROOMS:
      return {
        ...state,
        rooms: [],
      }
    case BookingContextActionEnum.ADD_USER_DATA:
      if (action.payload?.userData) {
        return {
          ...state,
          userData: action.payload?.userData
        }
      }
      return { ...state };
    case BookingContextActionEnum.ADD_ROOM_CONFIG:
      return {
        ...state,
        room_configuration: action.payload?.room_configuration || ''
      }
    case BookingContextActionEnum.ADD_COUPON:
      return {
        ...state,
        coupon: action.payload?.coupon
      }
    default:
      return { ...state }
  }
}

const BookingContextProvider: FC<ContextProps> = (props: ContextProps) => {
  const [state, dispatch] = useReducer(handleAction, initialData);

  return (
    <BookingContext.Provider value={{ state, dispatch }}>
      {props.children}
    </BookingContext.Provider>
  )
}

const useBookingContext = () => useContext(BookingContext);
export { BookingContextProvider, useBookingContext };