import styled from '@emotion/styled';
import { alpha, AppBar, Grid } from '@mui/material';

export const StyledAppBar = styled(AppBar)({
  height: 100,
  position: "sticky"
});

export const StyledGrid = styled(Grid)({
  paddingLeft: 32,
  paddingRight: 32
});