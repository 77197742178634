import { Checkbox, IconButton, InputLabel, MenuItem, Select, TableCell, TableRow } from "@mui/material";
import { FC, useState } from "react";
import RoomDropdown from "../../../../common/components/RoomDropdown";
import { PriceRowProps } from "../../../../../types/shared";
import { Delete, Edit, Update } from "@mui/icons-material";
import { useUserContext } from "../../../../context/UserContext";
import { updateProgramRoom } from "../../../../api/program-rooms";
import PriceModal from "./PriceModal";
import { PriceInput } from "../../../../../types/context";

const PriceRow: FC<PriceRowProps> = (props: PriceRowProps) => {
  const {
    additional_price,
    double_price,
    end_date,
    room_id,
    rooms,
    single_price,
    start_date,
    deletePrice,
    program_id,
    reloadProgramRooms
  } = props;

  const { user } = useUserContext();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [priceInput, setPriceInput] = useState<PriceInput>({
    start_date,
    end_date,
    single_price,
    additional_price,
    double_price,
    program_id,
    room_id
  });
  const handleInput = (key: keyof typeof priceInput, value: number | string) => setPriceInput(prev => ({ ...prev, [key]: value }))

  return (
    <>
      <PriceModal
        handleClose={handleClose}
        open={open}
        handleNewPrice={handleInput}
        reloadProgramRooms={reloadProgramRooms}
        rooms={rooms}
        additional_price={priceInput.additional_price}
        double_price={priceInput.double_price}
        end_date={priceInput.end_date}
        isEditing
        program_id={priceInput.program_id}
        room_id={priceInput.room_id}
        single_price={priceInput.single_price}
        start_date={priceInput.start_date}
      />
      <TableRow>
        <TableCell>{rooms.find(room => Number(room.id) === room_id)?.name || ''}</TableCell>
        <TableCell>{single_price}</TableCell>
        <TableCell>{double_price}</TableCell>
        <TableCell>{additional_price}</TableCell>
        <TableCell>{start_date.split('T')[0]}</TableCell>
        <TableCell>{end_date.split('T')[0]}</TableCell>
        <TableCell>
          <IconButton
            onClick={handleOpen}
          >
            <Edit fontSize="large" />
          </IconButton>
          <IconButton
            onClick={() => deletePrice(room_id, program_id, start_date, end_date)}
          >
            <Delete fontSize="large" />
          </IconButton>
        </TableCell>
      </TableRow>
    </>

  )
}

export default PriceRow;