import { Alert, Grid } from "@mui/material";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Program } from "../../../types/context";
import CustomStepper from "../../common/components/CustomStepper";
import Header from "../../common/components/Header";
import { useBookingContext } from "../../context/BookingContext";
import { BookingContextActionEnum } from "../../enum";
import { StyledContainer } from "./step1-styles";
import ProgramCard from "./components/SuggestionCard";
import { BASE_URL } from "../../api/config";
import { useSnackbarContext } from "../../context/SnackbarContext";
import SuggestionCard from "./components/SuggestionCard";
import dayjs from "dayjs";

const Suggestions: FC = () => {
  const { state, dispatch } = useBookingContext();
  const { showSnackBar, isOpen, message } = useSnackbarContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (state.programs.length === 0) {
      navigate("/");
    }
  }, [navigate, state.endDate, state.startDate]);

  const handleSubmit = (
    program: Program,
    startDate: string,
    endDate: string
  ) => {
    dispatch({
      type: BookingContextActionEnum.ADD_START_DATE,
      payload: {
        date: dayjs(startDate),
      },
    });

    dispatch({
      type: BookingContextActionEnum.ADD_END_DATE,
      payload: {
        date: dayjs(endDate),
      },
    });

    const query =
      `?startDate=${startDate}&endDate=${endDate}` +
      `&adults=${state.guests.adults}&children=${state.guests.children}`;

    fetch(BASE_URL + "rooms/" + program.id + query, {
      method: "GET",
    })
      .then((data) => {
        if (data.status === 404) {
          throw new Error("No availability");
        }
        return data.json();
      })
      .then((response) => {
        if (Object.keys(response).length === 0) {
          showSnackBar(
            true,
            "No contamos con disponibilidad para la fecha solicitada, por favor seleccionar otra fecha o contactarse a reservas +56224111720 / reservas@jahuel.cl"
          );
          return navigate("/");
        }
        dispatch({
          type: BookingContextActionEnum.ADD_ROOMS,
          payload: {
            rooms: response,
          },
        });
        dispatch({
          type: BookingContextActionEnum.SELECT_PROGRAM,
          payload: {
            selectedProgram: program,
          },
        });
        return navigate("/habitaciones");
      })
      .catch((e) => {
        showSnackBar(
          true,
          "No contamos con disponibilidad para la fecha solicitada, por favor seleccionar otra fecha o contactarse a reservas +56224111720 / reservas@jahuel.cl"
        );
        navigate("/");
      });
  };

  return (
    // Pasar esto a componente layout
    <StyledContainer maxWidth={false} disableGutters>
      <Header opacity={0.8} />
      <CustomStepper activeStep={0} />
      <Grid container justifyContent="center" marginTop={4}>
        <Alert severity="info">
          Le sugerimos los siguientes programas basados en su búsqueda:
        </Alert>
      </Grid>
      <Grid container padding={2} justifyContent="center">
        {state.programs &&
          state.programs.map((program) => (
            <Grid item xs={12} sm={4} md={3} margin={4} marginTop={1}>
              <SuggestionCard
                program={program}
                title={program.name}
                handleSubmit={handleSubmit}
                startDate={program.start_date}
                endDate={program.end_date}
              />
            </Grid>
          ))}
      </Grid>
    </StyledContainer>
  );
};

export default Suggestions;
