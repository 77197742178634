import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import { ConfirmModalProps } from "../../../types/shared";
import { useState } from "react";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
    maxHeight: '90%',
    overflow: 'auto'
};

const ConfirmModal = (props: ConfirmModalProps) => {
    const {
        onConfirmClick,
        onClose,
        open
    } = props;
    
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h4" component="h3" marginBottom={4}>
                    Estás seguro que quieres eliminar el registro?
                </Typography>
                <Grid>
                    <Button
                        onClick={onConfirmClick}
                        variant="contained"
                        color="error"
                        sx={{
                            marginRight: 4
                        }}
                    >
                        Confirmar
                    </Button>
                    <Button
                        onClick={onClose}
                    >
                        Cancelar
                    </Button>
                </Grid>
            </Box>
        </Modal>
    );
}

export default ConfirmModal;