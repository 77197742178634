import { FC } from "react";
import { Dayjs } from 'dayjs';
import 'dayjs/locale/es';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { createTheme, Grid, TextField, Typography } from "@mui/material";
import { LocalizationProvider, MobileDatePicker, MobileDateTimePicker } from '@mui/x-date-pickers';
import { StepOneDatePickerProps } from "../../../../types/step1";
import { StyledStaticDatePicker } from "../date-selection";
import { ThemeProvider } from "@emotion/react";
import calendarTheme from "../../../themes/calendar";
import { useBookingContext } from "../../../context/BookingContext";
import { BookingContextActionEnum } from "../../../enum";
import { esES } from "@mui/x-date-pickers";

const color = "#FFF";

const mobileCalendarTheme = createTheme({
  components: {
    MuiIconButton: {
      styleOverrides: {
        colorPrimary: color,
        colorInherit: color,
        colorSecondary: color
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color
        }
      }
    },
  },
});

const DatePicker: FC<StepOneDatePickerProps> = (props: StepOneDatePickerProps) => {
  const { title, type } = props;
  const { state, dispatch } = useBookingContext();

  const handleInitialValue = () => {
    if (type === "start") {
      return state.startDate;
    }

    return state.endDate;
  }

  const handleChange = (value: Dayjs) => {
    if (value) {
      if (type === "start") {
        return dispatch({
          type: BookingContextActionEnum.ADD_START_DATE,
          payload: {
            date: value
          }
        });
      }

      return dispatch({
        type: BookingContextActionEnum.ADD_END_DATE,
        payload: {
          date: value
        }
      });
    }
  }

  return (
    <Grid>
      <Typography color="white" fontSize={18} marginBottom={2}>{title}</Typography>
      <ThemeProvider theme={calendarTheme}>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale="es"
          localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}>
          <Grid color="secondary" display={{ xs: "flex", sm: "none" }} sx={{ marginBottom: "8px" }}>
            <ThemeProvider theme={mobileCalendarTheme}>
              <MobileDatePicker
                disablePast

                value={handleInitialValue()}
                onChange={(newValue) => {
                  handleChange(newValue as Dayjs);
                }}
                renderInput={(params) => <TextField {...params} sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#FFF"
                  }
                }}/>}
              />
            </ThemeProvider >
          </Grid>
          <Grid display={{ xs: "none", sm: "flex" }}>
            <StyledStaticDatePicker
              displayStaticWrapperAs="desktop"
              disablePast
              value={handleInitialValue()}
              onChange={(newValue) => {
                handleChange(newValue as Dayjs);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>

        </LocalizationProvider>
      </ThemeProvider>
    </Grid>
  )
}

export default DatePicker;