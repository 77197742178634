import { FC } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Card, CardContent, CardMedia, Grid, Paper, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ProgramCardProps } from '../../../../types/step2';
import YellowButton from "../../../common/components/YellowButton";

const ProgramCard: FC<ProgramCardProps> = (props: ProgramCardProps) => {
  const {
    program,
    title,
    handleSubmit
  } = props;

  return (
    <Card
      sx={{
        minWidth: "260px",
      }}>
      <CardMedia
        component="img"
        alt="habitación"
        width="180"
        height="240"
        image={`/images/${program.image}`}
      />
      <CardContent>
        <Grid container justifyContent="center">
          <Grid xs={12} item height={60} textAlign={'center'}>
            <Typography>{title}</Typography>
          </Grid>
          <Grid xs={12} item marginBottom={4}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography textAlign="center">Ver lo que incluye</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Paper variant="outlined" sx={{
                  padding: "8px",
                  border: "1px dashed black"
                }}>
                  <Typography>Este programa incluye:</Typography>
                  <ul dangerouslySetInnerHTML={{ __html: program.description }} />
                  {program.not_allowed && (
                    <>
                      <Typography>Este programa no incluye:</Typography>
                      <ul dangerouslySetInnerHTML={{ __html: program.not_allowed }} />
                    </>)}
                  {program.details && (
                    <>
                      <Typography>Detalles:</Typography>
                      <ul dangerouslySetInnerHTML={{ __html: program.details }} />
                    </>
                  )}

                </Paper>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item>
            {handleSubmit && <YellowButton handleSubmit={() => handleSubmit(program)} text="Elegir" />}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default ProgramCard;