import { Grid, Paper, TextField, Typography } from "@mui/material";
import { FC, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import YellowButton from "../../../common/components/YellowButton";
import { useBookingContext } from "../../../context/BookingContext";
import { BookingContextActionEnum } from "../../../enum";

const PersonalDataForm: FC = () => {
  const { state, dispatch } = useBookingContext();
  const navigate = useNavigate();
  const [userData, setUserData] = useState(state.userData);

  const handleName = (name: string) => {
    setUserData(prev => ({
      ...prev,
      name
    }))
  }
  const handleEmail = (email: string) => {
    setUserData(prev => ({
      ...prev,
      email
    }))
  }
  const handlePhone = (phone: string) => {
    setUserData(prev => ({
      ...prev,
      phone
    }))
  }

  const handleComment = (comment: string) => {
    setUserData(prev => ({
      ...prev,
      comment
    }))
  }

  const handleSubmit = () => {
    dispatch({
      type: BookingContextActionEnum.ADD_USER_DATA,
      payload: {
        userData
      }
    })
    navigate('/pago');
  }

  const isDisabled = useMemo(() => !userData.name || !userData.email || !userData.phone,
    [userData.email, userData.name, userData.phone]);

  return (
    <Grid item container xs={12} sm={6} md={6}>
      <Grid item xs={12}>
        <Paper>
          <Grid container sx={{ padding: "30px" }}>
            <Grid item xs={12} justifyContent="center" marginBottom={4}>
              <Typography fontWeight="bold" textAlign="center">DATOS PERSONALES</Typography>
            </Grid>
            <Grid item container direction="row">
              <Grid item xs={12} marginBottom={4}>
                <TextField
                  fullWidth
                  color="secondary"
                  id="name"
                  label="Nombre completo"
                  placeholder='Ej. Clemente León Schmidt Errázuriz'
                  variant="standard"
                  onChange={(e) => handleName(e.target.value)}
                  value={userData.name}
                  InputLabelProps={{
                    shrink: true,
                    size: "normal",
                    sx: {
                      fontSize: "20px"
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} marginBottom={4}>
                <TextField
                  fullWidth
                  color="secondary"
                  id="email"
                  label="Correo"
                  placeholder='Ej. clemente.schmidt@gmail.com'
                  variant="standard"
                  onChange={(e) => handleEmail(e.target.value)}
                  value={userData.email}
                  InputLabelProps={{
                    shrink: true,
                    size: "normal",
                    sx: {
                      fontSize: "20px"
                    }
                  }}
                  InputProps={{
                    type: "email"
                  }}
                />
              </Grid>
              <Grid item xs={12} marginBottom={3}>
                <TextField
                  fullWidth
                  color="secondary"
                  id="phone"
                  label="Teléfono"
                  placeholder='Ej. +569 XXXXXXXX'
                  variant="standard"
                  onChange={(e) => handlePhone(e.target.value)}
                  value={userData.phone}
                  InputLabelProps={{
                    shrink: true,
                    size: "normal",
                    sx: {
                      fontSize: "20px"
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} marginBottom={2}>
                <TextField
                  fullWidth
                  id="outlined-multiline-static"
                  label="Ingrese un comentario"
                  placeholder="Ingrese un comentario"
                  multiline
                  rows={4}
                  color="secondary"
                  onChange={(e) => handleComment(e.target.value)}
                  value={userData.comment}

                />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} justifyContent="end" textAlign="end" paddingRight={4} marginTop={2}>
        <YellowButton text='Siguiente' handleSubmit={handleSubmit} disabled={isDisabled} />
      </Grid>
    </Grid>
  )
}

export default PersonalDataForm;