import { createContext, FC, useContext, useState } from "react";
import { UserContextProps } from "../../types/context";

type User = {
  name: string,
  email: string,
  token: string
}

const UserContext = createContext<{ user?: User, setUser: (user: User) => void }>({
  user: undefined,
  setUser: () => null
});

const UserContextProvider: FC<UserContextProps> = (props: UserContextProps) => {
  const [user, setUser] = useState<User>();

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {props.children}
    </UserContext.Provider>
  )
}

const useUserContext = () => useContext(UserContext);

export {UserContextProvider, useUserContext};