import { Grid, Typography } from "@mui/material";
import { FC } from "react";
import { SuccessProps } from '../../../../types/payment';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import YellowButton from "../../../common/components/YellowButton";
import { useNavigate } from "react-router-dom";

const Success: FC<SuccessProps> = (props: SuccessProps) => {
  const navigate = useNavigate();

  const handleSubmit = () => {
    navigate('/');
  }
  return (
    <>
      <Grid item xs={12} textAlign="center" marginBottom={2}>
        <CheckCircleOutlineTwoToneIcon color="success" sx={{ fontSize: "96px" }} />
      </Grid>
      <Grid item xs={12} textAlign="center" marginBottom={2}>
        <Typography color="success" variant="h5">La reserva ha sido exitosa</Typography>
        <Typography variant="h6">Por favor revise su correo para ver los detalles de la reserva</Typography>
      </Grid>
      <Grid item xs={12} textAlign="center" marginBottom={2}>
        <YellowButton text="Ir al inicio" handleSubmit={handleSubmit}/>
      </Grid>

    </>
  )
}

export default Success;