import { FC, useState } from "react";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { HeaderProps } from "../../../types/shared";
import { StyledAppBar, StyledGrid } from "../styles/header-styles";
import { alpha, Box, IconButton, Menu, MenuItem } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';

const Header: FC<HeaderProps> = (props: HeaderProps) => {
  const { opacity } = props;
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const pages = ['INICIO', 'NOSOTROS', 'PROGRAMAS', 'EVENTOS', 'GALERIA', 'HOTEL', 'CONTACTO'];

  return (
    <StyledAppBar sx={{ backgroundColor: alpha("#000", opacity) }}>
      <StyledGrid
        container
        justifyContent="center"
        alignContent="center"
        height="100%"
      >
        <Box
          position="fixed"
          sx={{
            flexGrow: 1,
            display: { xs: 'flex', md: 'none' },
            width: "100px",
            left: 20,
            top: 20,
          }}
        >
          <IconButton
            size="large"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="primary"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: 'block', md: 'none' },
            }}
          >
            {pages.map((page) => (
              <MenuItem key={page} onClick={handleCloseNavMenu}>
                <Typography textAlign="center">{page}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
        <Grid
          item
          container
          sx={{ display: { xs: 'none', md: 'flex' } }}
          xs={5}
          md={5}
          justifyContent="space-evenly"
          alignContent="center"
          textAlign="center"
        >
          <Typography color="primary" fontSize={14} fontWeight={200}><a href="https://www.jahuel.cl/es/">INICIO</a></Typography>
          <Typography color="primary" fontSize={14} fontWeight={200}><a href="https://www.jahuel.cl/es/nosotros">NOSOTROS</a></Typography>
          <Typography color="primary" fontSize={14} fontWeight={200}><a href="https://www.jahuel.cl/es/programas">PROGRAMAS</a></Typography>
        </Grid>
        <Grid item container xs={12} md={2} justifyContent="center" alignContent="center">
          <img alt="jahuel-logo" src='/images/logo-white.png' height={100} width={100} />
        </Grid>
        <Grid
          item
          container
          sx={{ display: { xs: 'none', md: 'flex' } }}
          xs={5}
          md={5}
          justifyContent="space-evenly"
          alignContent="center"
          textAlign="center"
        >
          <Typography color="primary" fontSize={14} fontWeight={200}><a href="https://www.jahuel.cl/es/eventos">EVENTOS</a></Typography>
          <Typography color="primary" fontSize={14} fontWeight={200}><a href="https://www.jahuel.cl/es/galeria">GALERIA</a></Typography>
          <Typography color="primary" fontSize={14} fontWeight={200}><a href="https://www.jahuel.cl/es/hotel">HOTEL</a></Typography>
          <Typography color="primary" fontSize={14} fontWeight={200}><a href="https://www.jahuel.cl/es/contacto">CONTACTO</a></Typography>
        </Grid>
      </StyledGrid>
    </StyledAppBar>
  )
}

export default Header;