import { Box, Button, Grid, InputLabel, MenuItem, Modal, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import { DatePicker, LocalizationProvider, esES } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import Title from "../../../../common/components/Title"
import { useUserContext } from "../../../../context/UserContext"
import { FC, useEffect, useState } from "react"
import { Coupon, CouponInput, Program, SpecialDay, SpecialDayInput } from "../../../../../types/context"
import { BASE_URL } from "../../../../api/config"
import SpecialDayRow from "./SpecialDayRow"
import dayjs, { Dayjs } from "dayjs"
import { createSpecialDay, getAllSpecialDays } from "../../../../api/special-days"

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  maxHeight: '90%',
  overflow: 'auto'
};

const SpecialDays: FC = () => {
  const { user } = useUserContext();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [specialDays, setSpecialDays] = useState<Array<SpecialDay>>([]);
  const [newSpecialDay, setNewSpecialDay] = useState<SpecialDayInput>({
    start_date: dayjs().format("YYYY-MM-DD"),
    end_date: dayjs().format("YYYY-MM-DD"),
  });

  const handleNewSpecialDay = (key: keyof typeof newSpecialDay, value: number | string) => setNewSpecialDay(prev => ({ ...prev, [key]: value }))
  const handleGetSpecialDays = () => {
    if (user) {
      getAllSpecialDays(user.token)
        .then(response => setSpecialDays(response as Array<SpecialDay>));
    }
  }

  const createNewSpecialDay = () => {
    if (user && newSpecialDay.start_date && newSpecialDay.end_date && newSpecialDay.start_date <= newSpecialDay.end_date) {
      createSpecialDay(user.token, newSpecialDay)
        .then(() => {
          handleClose();
          handleGetSpecialDays();
        })
        .catch(e => console.log(e))
    }
  }

  useEffect(() => {
    handleGetSpecialDays();
  }, [user])

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h4" component="h2" marginBottom={4}>
            Nuevo Feriado
          </Typography>
          <Box
            component="form"
            display='flex'
            flexDirection='column'
            noValidate
            autoComplete="off"
          >
            <Grid
              display='flex'
              justifyContent='start'
              columnGap={4}
            >
              <TextField
                required
                sx={{
                  width: "400px",
                  marginBottom: 4
                }}
                id="description"
                label="Descripción"
                placeholder='Ingrese descripción'
                variant="standard"
                value={newSpecialDay.description}
                onChange={(e) => handleNewSpecialDay('description', e.target.value)}
              />

              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="es"
                localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
              >
                <DatePicker
                  label="Fecha Inicio"
                  value={dayjs(newSpecialDay.start_date)}
                  onChange={(newValue: Dayjs | null) => {
                    if (newValue) handleNewSpecialDay('start_date', newValue.format("YYYY-MM-DD"));
                  }}
                  renderInput={(params) => <TextField {...params} sx={{ marginRight: 4 }} />}
                  inputFormat="YYYY-MM-DD"
                  disablePast

                />
                <DatePicker
                  label="Fecha Fin"
                  value={dayjs(newSpecialDay.end_date)}
                  onChange={(newValue: Dayjs | null) => {
                    if (newValue) handleNewSpecialDay('end_date', newValue.format("YYYY-MM-DD"));
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  inputFormat="YYYY-MM-DD"
                  disablePast
                />
              </LocalizationProvider>

            </Grid>
            <Grid
              marginTop={4}
              display='flex'
              justifyContent='flex-start'
            >
              <Button
                variant='contained'
                onClick={createNewSpecialDay}
                style={{
                  width: '256px'
                }}
              >
                Crear
              </Button>
            </Grid>
          </Box>
        </Box>
      </Modal>

      <Grid
        display='flex'
        minWidth='100%'
        justifyContent='space-between'
        marginBottom={2}
        marginTop={2}
      >
        <Title>Feriados</Title>
        <Button
          variant='contained'
          onClick={handleOpen}
        >
          Nuevo Feriado
        </Button>
      </Grid>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell size="small">Id</TableCell>
              <TableCell size="medium">Descripción</TableCell>
              <TableCell size="medium">Fecha Inicio</TableCell>
              <TableCell size="medium">Fecha Fin</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {specialDays && specialDays.map(specialDay => (<SpecialDayRow
              id={specialDay.id}
              start_date={specialDay.start_date}
              end_date={specialDay.end_date}
              description={specialDay.description}
              handleGetSpecialDays={handleGetSpecialDays}
              key={`special-day-${specialDay.id}-${specialDay.description}`}
            />))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default SpecialDays;
